import { Checkbox, FormControlLabel } from '@mui/material';

export type ConsentCheckboxProps = {
    label: React.ReactNode;
    checked: boolean;
    setChecked: (isChecked: boolean) => void;
};

export const ConsentCheckbox = (props: ConsentCheckboxProps) => {
    return (
        <FormControlLabel
            sx={{ margin: '0.5rem 0', alignItems: 'flex-start' }}
            label={props.label}
            control={
                <Checkbox
                    sx={{ marginTop: -1 }}
                    onChange={(e) => props.setChecked(e.target.checked)}
                    checked={props.checked}
                />
            }
        />
    );
};
