import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { toDecimal, useFormatting } from '../../utils/formatting';
import styled from '@emotion/styled';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import { getCurrentCompanyBalanceData } from '../../lib/slices/paymentsSlice';

const BalanceList = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const CompanyBalance = () => {
    const dispatch = useAppDispatch();
    const { currentCompanyBalance } = usePayments();
    const { getBalanceLabel } = useFormatting();

    useEffect(() => {
        dispatch(getCurrentCompanyBalanceData());
    }, []);

    return (
        <>
            {currentCompanyBalance.list &&
                currentCompanyBalance.list?.paymentSource ===
                    'InstaPaidAccount' && (
                    <>
                        <Box
                            sx={{
                                float: 'right',
                                textAlign: 'right',
                                fontSize: '1.1rem'
                            }}
                            boxShadow={'0px 0px 5px -2px gray'}
                            borderRadius={'12px'}
                            padding={'20px'}
                            marginLeft={'auto'}
                        >
                            <BalanceList>
                                <Typography textAlign={'left'}>
                                    Current balance:{' '}
                                </Typography>
                                <Typography
                                    textAlign={'right'}
                                    marginLeft={'50px'}
                                >
                                    {getBalanceLabel(
                                        currentCompanyBalance?.list
                                            ?.currentBalance,
                                        currentCompanyBalance?.list?.currency,
                                        true,
                                        undefined,
                                        true
                                    )}
                                </Typography>
                            </BalanceList>
                            <BalanceList>
                                <Typography textAlign={'left'}>
                                    Pending approval:{' '}
                                </Typography>
                                <Typography
                                    textAlign={'right'}
                                    marginLeft={'50px'}
                                >
                                    {getBalanceLabel(
                                        currentCompanyBalance?.list
                                            ?.awaitingAdminApprovalTotal,
                                        currentCompanyBalance?.list?.currency,
                                        true,
                                        undefined,
                                        true
                                    )}
                                </Typography>
                            </BalanceList>
                            <BalanceList>
                                <Typography
                                    fontWeight={'bold'}
                                    textAlign={'left'}
                                >
                                    Available balance:{' '}
                                </Typography>
                                <Typography
                                    fontWeight={'bold'}
                                    textAlign={'right'}
                                    marginLeft={'50px'}
                                >
                                    {getBalanceLabel(
                                        currentCompanyBalance?.list
                                            ?.availableBalance,
                                        currentCompanyBalance?.list?.currency,
                                        true,
                                        undefined,
                                        true
                                    )}
                                </Typography>
                            </BalanceList>
                        </Box>
                        <Box sx={{ clear: 'both' }}></Box>
                    </>
                )}
        </>
    );
};
