import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../components/ContentWrapper';
import { TransactionsList } from './TransactionsList';
import { Typography } from '@mui/material';

export const PaymentsPage = () => {
    const { t } = useTranslation();
    return (
        <ContentWrapper>
            <>
                <Typography variant="h5">
                    {t('pending_transactions')}
                </Typography>
                <TransactionsList />
            </>
        </ContentWrapper>
    );
};
