import { DialogActions, Button } from '@mui/material';
import { UpdateConsentsRequest } from '../../models/company';
import { TermsVersionsResponse } from '../../models/payment';
import { useNotificationsSnackbar } from '../snackbar/NotificationsSnackbarContext';

export type ConsentFormActionsProps = {
    commercialConsent: boolean;
    dataConsent: boolean;
    newsletterConsent: boolean;
    termsVersions: TermsVersionsResponse;
    updateConsents: (request: UpdateConsentsRequest) => void;
    postponeConsents: () => void;
};
export const ConsentFormActions = (props: ConsentFormActionsProps) => {
    const commercialTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Commercial'
    );
    const dataTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Data'
    );
    const { setAlert } = useNotificationsSnackbar();

    const onAccept = () => {
        if (!canAccept()) {
            setAlert(
                'You have to check the terms and conditions checkbox to accept',
                'error'
            );
            return;
        }

        props.updateConsents({
            commercialConsented: props.commercialConsent,
            dataConsented: props.dataConsent,
            newsletterAndUpdatesConsented: props.termsVersions
                .hasAcceptedNewsletterAndUpdates
                ? null
                : props.newsletterConsent,
            commercialTermsVersion: commercialTerms?.versionId ?? null,
            dataTermsVersion: dataTerms?.versionId ?? null
        });
    };

    const onPostpone = () => {
        props.postponeConsents();
    };

    const canAccept = () => {
        return (
            (dataTerms?.isAccepted || props.dataConsent) &&
            (commercialTerms?.isAccepted || props.commercialConsent)
        );
    };

    const acceptButtonEnabled = () => {
        return props.termsVersions.canBePostponed || canAccept();
    };

    return (
        <DialogActions sx={{ justifyContent: 'center' }}>
            {props.termsVersions.canBePostponed && (
                <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    title="Click to postpone"
                    sx={{ pointerEvents: 'auto !important' }}
                    onClick={onPostpone}
                >
                    Postpone
                </Button>
            )}
            <Button
                color="success"
                size="large"
                variant="contained"
                title={
                    props.commercialConsent
                        ? 'Click to accept'
                        : 'You have to accept our agreement to continue'
                }
                disabled={!acceptButtonEnabled()}
                sx={{ pointerEvents: 'auto !important' }}
                onClick={onAccept}
            >
                Accept
            </Button>
        </DialogActions>
    );
};
