import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useUser } from '../../contexts/user/UserContext';
import { UsersDetailedResponse } from '../../models/payment';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import { useErrorHandling } from '../../utils/errorHandling';

export const useEmployeesOverview = () => {
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();
    const { user } = useUser();

    const onRoleToggleChange = (
        userId: string,
        enabled: boolean,
        onSuccess: () => void
    ) => {
        setLoading(true);

        UsersAPIServiceV2.getInstance()
            .updateUserRole({
                userId: userId,
                role: enabled ? 'Admin' : 'User'
            })
            .then(() => {
                setAlert('Role updated successfully.', 'success');
            })
            .catch(backendErrorHandler('Error updating user role: '))
            .finally(() => {
                onSuccess();
                setLoading(false);
            });
    };

    const onUserChangeStatusClick = (
        id: string,
        checked: boolean,
        onSuccess: () => void
    ) => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .updateUsersEnabled(checked, { userIds: [id] })
            .catch(backendErrorHandler())
            .finally(() => {
                onSuccess();
                setLoading(false);
            });
    };

    const onUpdateSelectedClick = (
        enabled: boolean,
        userIds: string[],
        onSuccess: () => void
    ) => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .updateUsersEnabled(enabled, { userIds })
            .catch(backendErrorHandler('Error updating users status: '))
            .finally(() => {
                onSuccess();
                setLoading(false);
            });
    };

    const onRowClick =
        (
            userId: string,
            checked: string[],
            setChecked: (checked: string[]) => void
        ) =>
        () => {
            const currentIndex = checked.indexOf(userId);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(userId);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setChecked(newChecked);
        };

    const currentAdminVisible = (
        usersDetailed: UsersDetailedResponse | undefined
    ) => usersDetailed?.users.find((_) => _.userId === user.id);

    const allUsersChecked = (
        usersDetailed: UsersDetailedResponse | undefined,
        checked: string[]
    ) =>
        usersDetailed?.users.length !== undefined &&
        checked.length === filteredUsersLength(usersDetailed);

    const filteredUsersLength = (
        usersDetailed: UsersDetailedResponse | undefined
    ) => {
        let totalLength = usersDetailed?.users.length ?? 0;
        if (currentAdminVisible(usersDetailed)) {
            // Current user cannot be enabled/disabled, so he's subtracted from the total list
            totalLength -= 1;
        }
        return totalLength;
    };

    const onCheckAllClick =
        (
            usersDetailed: UsersDetailedResponse | undefined,
            checked: string[],
            setChecked: (checked: string[]) => void
        ) =>
        () => {
            const allChecked = allUsersChecked(usersDetailed, checked);
            let newChecked: string[];

            if (allChecked) {
                newChecked = [];
            } else {
                newChecked = usersDetailed?.users.map((_) => _.userId) ?? [];
                if (currentAdminVisible(usersDetailed)) {
                    const currentUserIndex = newChecked.indexOf(user.id);
                    newChecked.splice(currentUserIndex, 1);
                }
            }

            setChecked(newChecked);
        };

    /**
     * @deprecated This function is deprecated. Please use dispatch sendActivationEmail instead.
     * @param {string} userId - The ID of the user.
     * @param {any} setError - The function to set the error.
     */
    const onSendActivationMailClicked = (userId: string, setError: any) => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .sendActivationEmail(userId)
            .then((response: any) => {
                setAlert('Email request initiated.', 'success');
            })
            .catch((err) => {
                backendErrorHandler('Error on sending activation email', () => {
                    setError('Activiation email could not be sent');
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const allCompanyUsersChecked = (usersDetailed: any, checked: string[]) =>
        usersDetailed?.companySettingsUsers?.length !== undefined &&
        checked.length === filteredCompanyUsersLength(usersDetailed);

    const filteredCompanyUsersLength = (usersDetailed: any) => {
        let totalLength = usersDetailed?.companySettingsUsers?.length ?? 0;
        return totalLength;
    };

    const onCheckAllCompanyUsersClick =
        (
            usersDetailed: any,
            checked: string[],
            setChecked: (checked: string[]) => void
        ) =>
        () => {
            const allChecked = allCompanyUsersChecked(usersDetailed, checked);
            let newChecked: string[];

            if (allChecked) {
                newChecked = [];
            } else {
                newChecked =
                    usersDetailed?.companySettingsUsers?.map(
                        (_: any) => _.userId
                    ) ?? [];
            }

            setChecked(newChecked);
        };

    return {
        onRoleToggleChange,
        onUserChangeStatusClick,
        onUpdateSelectedClick,
        onRowClick,
        onCheckAllClick,
        allUsersChecked,
        onSendActivationMailClicked,
        allCompanyUsersChecked,
        onCheckAllCompanyUsersClick
    };
};
