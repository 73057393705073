import { Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import {
    IntectDepartmentsResponse,
    IntectEmploymentTemplatesResponse,
    PlanDayEmployeeTypeResponse,
    timeRegistrationProviders
} from '../../../models/payment';
import { useErrorHandling } from '../../../utils/errorHandling';
import { Company } from '../../../models/company';
import { SearchGrid } from '../../../components/SearchGrid';
import { SMBold } from '../../../typography/Typography';
import { SearchInput } from '../../../components/shared/SearchInput';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownType } from '../../../components/shared/Dropdown';
import { ActiveFilter } from '../UserActiveFilter';
import { useAppDispatch, usePayments } from '../../../lib/hooks';
import { FETCH_STATE } from '../../../lib/slices/types';
import {
    getIntectDepartments,
    getIntectEmploymentTemplates,
    getPlanDayEmployeeTypes
} from '../../../lib/slices/paymentsSlice';
import { SecondaryButtonRegular } from '../../../components/shared/Button';
import {
    getIntectUsersFilters,
    saveIntectUserFilters
} from '../../../lib/localStorage';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';

export type IntectUsersFiltersProps = {
    selectedDepartments: string[];
    onDepartmentChange: (value: string | string[]) => void;
    selectedEmploymentTemplates: string[];
    onEmploymentTemplateChange: (value: string | string[]) => void;
    username: string;
    selectedPlanDayEmployeeTypes: string[];
    onPlanDayEmployeeTypeChange: (value: string | string[]) => void;
    onUsernameChange: (name: string) => void;
    renderActiveOptions: () => any;
    selectedActiveType: string;
    onActiveTypeChange: (value: string | string[]) => void;
};

export const IntectUsersFilters = (props: IntectUsersFiltersProps) => {
    const [departments, setDepartments] = useState<IntectDepartmentsResponse>();
    const [employmentTemplates, setEmploymentTemplates] =
        useState<IntectEmploymentTemplatesResponse>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const [employeePlanDayTypes, setPlanDayEmployeeTypes] =
        useState<PlanDayEmployeeTypeResponse>();
    const [company, setCompany] = useState<Company>();

    const { planDayEmployeeType, intectDepartments, intectEmployeeTemplates } =
        usePayments();

    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoading(planDayEmployeeType.status == FETCH_STATE.LOADING);
        if (planDayEmployeeType) {
            setPlanDayEmployeeTypes(
                planDayEmployeeType.planDayEmployeeTypeResponse
            );
        }
        let filters = getIntectUsersFilters();
        handleEmployeeTypeChange(filters?.selectedPlanDayEmployeeTypes ?? []);
        if (planDayEmployeeType.error) {
            backendErrorHandler('Error fetching Planday employee types: ');
        }
    }, [planDayEmployeeType]);

    useEffect(() => {
        setLoading(intectDepartments.status == FETCH_STATE.LOADING);
        if (intectDepartments) {
            setDepartments(intectDepartments?.intectDepartments);
            let filters = getIntectUsersFilters();
            handleDepartmentChange(filters?.selectedDepartments ?? []);
            handleActiveTypeChange(filters?.selectedActive ?? []);
            // handleEmployeeTypeChange(filters?.selectedEmployeeTypes ?? []);
        }
        if (planDayEmployeeType.error) {
            backendErrorHandler('Error fetching departments: ');
        }
    }, [intectDepartments]);

    useEffect(() => {
        setLoading(intectEmployeeTemplates.status == FETCH_STATE.LOADING);
        if (intectEmployeeTemplates) {
            setEmploymentTemplates(
                intectEmployeeTemplates?.intectEmployeeTemplates
            );

            let filters = getIntectUsersFilters();
            handleEmploymentTemplates(
                filters?.selectedEmploymentTemplates ?? []
            );
        }
        if (intectEmployeeTemplates.error) {
            backendErrorHandler('Error fetching employment templates: ');
        }
    }, [intectEmployeeTemplates]);

    const loadDepartments = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompany(response.data);
            })
            .catch(backendErrorHandler('Error fetching company details: '))
            .finally(() => {
                setLoading(false);
            });
    }, [getIntectDepartments, backendErrorHandler, setLoading]);

    const { setAlert } = useNotificationsSnackbar();

    useEffect(() => {
        dispatch(getPlanDayEmployeeTypes());
        dispatch(getIntectDepartments());
        dispatch(getIntectEmploymentTemplates());
        loadDepartments();

        let filters = getIntectUsersFilters();

        if (!filters) {
            return;
        }

        let saved = false;
        Object.keys(filters).forEach((x) => {
            if (filters[x] != '' && filters[x]?.length > 0) {
                saved = true;
            }
        });

        if (saved) {
            setAlert('Previous filters restored', 'success');
        }
    }, [loadDepartments]);

    const { t } = useTranslation();

    const resetFilters = () => {
        handleDepartmentChange([]);
        handleEmploymentTemplates([]);
        handleActiveTypeChange([]);
        handleEmployeeTypeChange([]);
    };

    const handleDepartmentChange = (value: string[]) => {
        props.onDepartmentChange(value);
        saveIntectUserFilters({ selectedDepartments: value });
    };

    const handleEmploymentTemplates = (value: string[]) => {
        props.onEmploymentTemplateChange(value);
        saveIntectUserFilters({ selectedEmploymentTemplates: value });
    };

    const handleActiveTypeChange = (value: string | string[]) => {
        props.onActiveTypeChange(value);
        saveIntectUserFilters({ selectedActive: value as string });
    };

    const handleEmployeeTypeChange = (value: string[]) => {
        props.onPlanDayEmployeeTypeChange(value);
        saveIntectUserFilters({ selectedPlanDayEmployeeTypes: value });
    };

    return (
        <SearchGrid container columnSpacing={1}>
            <Grid item xs={2}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    justifyContent={'space-between'}
                >
                    <div>
                        <SMBold noWrap={true}>{t('Filter by: ')}</SMBold>
                    </div>

                    <SearchInput
                        onDebouncedTextChange={props.onUsernameChange}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <Dropdown
                    label="Department"
                    choices={departments?.departments
                        .map((department) => ({
                            label: department.name,
                            value: department.id
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    value={props.selectedDepartments}
                    type={DropdownType.Multi}
                    onChange={handleDepartmentChange}
                />
            </Grid>

            {company?.timeRegistrationProvider ==
                timeRegistrationProviders[1] &&
                employeePlanDayTypes?.employeeTypes && (
                    <Grid item xs={12} sm={2} md={2}>
                        <Dropdown
                            label=" Employment Type"
                            type={DropdownType.Multi}
                            choices={employeePlanDayTypes?.employeeTypes
                                ?.map((employee) => ({
                                    label: employee.name,
                                    value: employee.id
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label))}
                            value={props.selectedPlanDayEmployeeTypes}
                            onChange={handleEmployeeTypeChange}
                        />
                    </Grid>
                )}
            <Grid item xs={12} sm={3} md={3}>
                <Dropdown
                    label="Employment Template"
                    type={DropdownType.Multi}
                    choices={employmentTemplates?.employmentTemplates
                        ?.map((employmentTemplate) => ({
                            label: employmentTemplate.name,
                            value: employmentTemplate.id
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    value={props.selectedEmploymentTemplates}
                    onChange={handleEmploymentTemplates}
                />
            </Grid>

            <Grid item xs={12} sm={2} md={1.5}>
                <ActiveFilter
                    selectedActiveType={props.selectedActiveType}
                    onActiveTypeChange={handleActiveTypeChange}
                />
            </Grid>

            <Grid item>
                <SecondaryButtonRegular onClick={resetFilters}>
                    <Typography
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={600}
                        noWrap
                    >
                        Reset
                    </Typography>
                </SecondaryButtonRegular>
            </Grid>
        </SearchGrid>
    );
};
