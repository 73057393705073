export const paymentSources = {
    instaPaidAccount: 'InstaPaidAccount',
    companyAccount: 'CompanyAccount'
};

export const paymentProviders = {
    aiia: 'Aiia',
    danskeBank: 'DanskeBank'
};

export const approvalTypes = {
    manual: 'Manual',
    automatic: 'Automatic'
};
