import { useIsAuthenticated } from '@azure/msal-react';
import { AxiosError } from 'axios';
import {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback
} from 'react';
import { Company, CurrentUser, LoginType, Theme } from '../../models/company';
import { useLoader } from '../loader/LoaderContext';
import { UserLoadErrorPage } from './UserLoadErrorPage';
import { BackendError, isBackendError } from '../../utils/errorHandling';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import { getCompanyIdFromLocalStorage } from '../../services/companyService';
import { useLocation, useNavigate } from 'react-router-dom';

type UserProviderProps = {
    children: JSX.Element;
};

type State = {
    user: CurrentUser;
    company: Company;
};
const initialState: State = {
    user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        role: 'User'
    },
    company: {
        companyId: '',
        createdAt: new Date(),
        currency: 'DKK',
        greetingText: '',
        timeRegistrationProvider: '',
        loginType: LoginType.AzureAD,
        name: '',
        theme: { logoName: '/instapaid_long.png' } as Theme,
        endOfTrial: null,
        requiredConsentsAccepted: true
    }
};

export const UserContext = createContext({
    ...initialState,
    reloadUserData: () => {}
});

export const UserProvider = (props: UserProviderProps) => {
    const { setLoading } = useLoader();

    const isAuthenticated = useIsAuthenticated();

    const [user, setUser] = useState<CurrentUser>();
    const [company, setCompany] = useState<Company>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorStatusCode, setErrorStatusCode] = useState<number>();
    const [backendError, setBackendError] = useState<BackendError>();

    const navigation = useNavigate();
    const location = useLocation();

    const handleError = (e: any) => {
        let errorDetails: BackendError | undefined = undefined;
        if (e instanceof AxiosError && isBackendError(e.response?.data)) {
            errorDetails = e.response?.data as BackendError;
            setBackendError(errorDetails);
            setErrorStatusCode(+errorDetails.status);
            setErrorMessage(errorDetails.detail);
        } else {
            const error = 'Could not get user - ' + e.message;
            setErrorStatusCode((e as AxiosError).response?.status ?? undefined);
            setErrorMessage(error);
        }
    };

    const loadUserData = useCallback(() => {
        if (!isAuthenticated) {
            return;
        }

        if (
            !getCompanyIdFromLocalStorage() &&
            location.pathname !== 'companies/v2'
        ) {
            navigation('companies/v2');
            //return;
        }

        if (!getCompanyIdFromLocalStorage()) {
            return;
        }

        setLoading(true);
        setErrorMessage(undefined);

        const userPromise = UsersAPIServiceV2.getInstance()
            .getCurrentUser()
            .then((response: any) => {
                setUser(response.data);
            });

        UsersAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompany(response.data);
            })
            .catch((e: Error) => {
                if (getCompanyIdFromLocalStorage()) {
                    handleError(e);
                } else {
                    console.log('No company id in local storage');
                }
            });

        Promise.all([userPromise])
            .catch((e: Error) => {
                let errorDetails: BackendError | undefined = undefined;
                if (
                    e instanceof AxiosError &&
                    isBackendError(e.response?.data)
                ) {
                    errorDetails = e.response?.data as BackendError;
                    setBackendError(errorDetails);
                    setErrorStatusCode(+errorDetails.status);
                    setErrorMessage(errorDetails.detail);
                } else {
                    const error = 'Could not get user - ' + e.message;
                    setErrorStatusCode(
                        (e as AxiosError).response?.status ?? undefined
                    );
                    setErrorMessage(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, isAuthenticated]);

    useEffect(() => {
        loadUserData();
    }, [loadUserData]);

    return (
        <>
            {errorMessage ? (
                <UserLoadErrorPage
                    backendError={backendError}
                    statusCode={errorStatusCode}
                />
            ) : (
                <>
                    {
                        <UserContext.Provider
                            value={{
                                user: user ?? { ...initialState.user },
                                company: company ?? { ...initialState.company },
                                reloadUserData: loadUserData
                            }}
                        >
                            {props.children}
                        </UserContext.Provider>
                    }
                </>
            )}
        </>
    );
};

export const useUser = () => useContext(UserContext);
