import {
    Table,
    TableBody,
    TableContainer,
    Button,
    Alert,
    Menu,
    MenuItem,
    useTheme,
    Card
} from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';
import { useAppDispatch } from '../../lib/hooks';
import {
    IntectUserDetailed,
    PaymentsUser,
    UserDetailed,
    ZenegyUserDetailed
} from '../../models/payment';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import Chip, { chipColors } from '../../components/shared/Chip';
import {
    TableCell,
    TableCellNoPadding,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellWithCheckbox,
    TableRow,
    TableSortLabel
} from '../../components/shared/styles/Table';
import { TablePagination } from '../../components/shared/TablePagination';
import { SMBold } from '../../typography/Typography';
import CardHeader from '../../components/shared/CardHeader';
import { Checkbox } from '../../components/shared/CheckBox';
import { getCurrentCompanyWithUsers } from '../../lib/slices/usersSlice';
import { UserRoles } from '../../components/sidebar/MenuTree';
import { EditUserDialog } from '../company/EditUsersDialog';

export enum UserTableType {
    Zenergy = 'zenergy',
    Intect = 'intect'
}

export type SettingsUsersTableProps = {
    usersDetailed: any;
    checked: string[];
    allChecked: boolean;
    onStatusClick: (userId: string, isActive: boolean) => void;
    onRoleToggleChange: (userId: string, isAdmin: boolean) => void;
    onRowClick: (userId: string) => () => void;
    onCheckAllClick: () => () => void;
    onReloadUser: any;
    onUpdateBankAccountDetailsClick: (userId: string) => () => void;
    onSendActivationMailClicked: (userId: string, name: string) => void;
    handleBulkEnable?: (checked: string[]) => void;
    handleBulkDisable?: (checked: string[]) => void;
    handleDeleteUser?: (userId: string) => void;
    total?: number;
    handleChangePage?: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        page: number
    ) => void;
    handleChangeRowsPerPage?: (rowsPerPage: number) => void;
    rowsPerPage: number;
    userTableType: UserTableType;
    displayAddUserForm?: boolean;
    addUserForm?: any;
};

type SortingType = 'asc' | 'desc' | undefined;

type KeysPaymentsUser = keyof ZenegyUserDetailed & keyof IntectUserDetailed;

export const SettingsUsersTable = ({
    usersDetailed,
    total,
    handleBulkEnable,
    handleBulkDisable,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDeleteUser,
    rowsPerPage,
    userTableType,
    checked,
    displayAddUserForm,
    addUserForm,
    ...props
}: SettingsUsersTableProps) => {
    const dispatch = useAppDispatch();
    const { user } = useUser();
    const [checkedMap, setCheckedMap] = useState<{ [key: string]: boolean }>(
        {}
    );
    const [editUserOpen, setEditUserOpen] = useState(false);

    useEffect(() => {
        dispatch(getCurrentCompanyWithUsers());
    }, []);

    useEffect(() => {
        let map = {} as { [key: string]: boolean };

        checked.forEach((userId) => {
            map[userId] = true;
        });
        setCheckedMap(map);
    }, [checked]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [rowUser, setRowUser] = useState<PaymentsUser>();

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        rowUser: PaymentsUser
    ) => {
        setAnchorEl(event.currentTarget);
        setRowUser(rowUser);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme();

    const getStatusView = (
        id: string,
        isActive: boolean,
        isDisabled: boolean
    ) => {
        let text = 'Imported';
        let color = 'muted';

        if (isActive && !isDisabled) {
            text = 'Enabled';
            color = 'success';
        }
        if (!isActive && isDisabled) {
            text = 'Disabled';
            color = 'error';
        }

        return <Chip label={text} color={color as any} />;
    };

    const { t } = useTranslation();

    const getStatusButton = (id: string, isActive: boolean) => {
        const color = isActive ? 'success' : 'error';
        const text = isActive ? 'Disable' : 'Enable';

        return (
            <div
                style={{ width: '100%' }}
                onClick={() => {
                    handleMenuClose();
                    props.onStatusClick(id, !isActive);
                }}
                color={color}
            >
                {text}
            </div>
        );
    };

    const renderCheckBox = useCallback(
        (rowUser: any) => {
            return (
                <Checkbox
                    sx={{
                        marginLeft: '10px',
                        padding: 0
                    }}
                    checked={checkedMap[rowUser.userId] ?? false}
                    inputProps={{
                        'aria-labelledby': `checkbox-list-secondary-label-${rowUser.userId}`
                    }}
                />
            );
        },
        [checkedMap]
    );

    const [nameSortDirection, setNameSortDirection] =
        useState<SortingType>(undefined);
    const [emailSortDirection, setEmailSortDirection] =
        useState<SortingType>(undefined);
    const [mobileSortDirection, setMobileSortDirection] =
        useState<SortingType>(undefined);
    const [departmentSortDirection, setDepartmentSortDirection] =
        useState<SortingType>(undefined);
    const [statusSortDirection, setStatusSortDirection] =
        useState<SortingType>(undefined);
    const [accessTypeSortDirection, setAccessTypeSortDirection] =
        useState<SortingType>(undefined);

    const sortedUserList = useMemo(() => {
        let list = usersDetailed?.companySettingsUsers
            ? [...usersDetailed.companySettingsUsers]
            : [];

        const sortArr = [
            {
                key: 'firstName' as KeysPaymentsUser,
                direction: nameSortDirection
            },
            {
                key: 'email' as KeysPaymentsUser,
                direction: emailSortDirection
            },
            {
                key: 'phoneNumber' as KeysPaymentsUser,
                direction: departmentSortDirection
            },
            {
                key: 'role' as KeysPaymentsUser,
                direction: accessTypeSortDirection
            }
        ];

        sortArr.forEach((sortDirection) => {
            if (sortDirection.direction) {
                list.sort((a, b) => {
                    if (a[sortDirection.key] && b[sortDirection.key]) {
                        return (
                            a[sortDirection.key]!.toString().localeCompare(
                                b[sortDirection.key!]!.toString()
                            ) * (sortDirection.direction === 'asc' ? 1 : -1)
                        );
                    }
                    return 0;
                });
            }
        });

        return list;
    }, [
        usersDetailed.companySettingsUsers,
        nameSortDirection,
        emailSortDirection,
        mobileSortDirection,
        statusSortDirection,
        accessTypeSortDirection
    ]);

    const handleSort = useCallback(
        (key: SortingType, func: Function) => {
            setNameSortDirection(undefined);
            setEmailSortDirection(undefined);
            setMobileSortDirection(undefined);
            setStatusSortDirection(undefined);
            setAccessTypeSortDirection(undefined);

            key === 'asc' ? func('desc') : func('asc');
        },
        [
            usersDetailed.companySettingsUsers,
            nameSortDirection,
            emailSortDirection,
            mobileSortDirection,
            statusSortDirection,
            accessTypeSortDirection
        ]
    );

    const navigation = useNavigate();
    const navigateToUserDetails = (userId: string) => {
        navigation(`/users/${userId}`);
    };

    return (
        <>
            <Card>
                <CardHeader
                    label={t('users')}
                    chip={
                        <Chip
                            icon={<></>}
                            label={
                                <SMBold color={chipColors.success.text}>
                                    {`${sortedUserList.length} of ${usersDetailed.companySettingsUsers?.length}`}
                                </SMBold>
                            }
                            color={'success'}
                        />
                    }
                    actions={addUserForm}
                />
                <TableContainer>
                    {sortedUserList.length > 0 ? (
                        <Table sx={{}} aria-label="simple table">
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>
                                        <Checkbox
                                            onClick={props.onCheckAllClick()}
                                            sx={{
                                                marginLeft: '10px',
                                                padding: 0
                                            }}
                                            edge="start"
                                            checked={props.allChecked}
                                            tabIndex={-1}
                                            inputProps={{
                                                style: {
                                                    borderRadius: '4px'
                                                },
                                                'aria-labelledby':
                                                    'checkbox-list-secondary-label-all'
                                            }}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCellWithCheckbox>
                                        <TableSortLabel
                                            direction={nameSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    nameSortDirection,
                                                    setNameSortDirection
                                                )
                                            }
                                        >
                                            {t('name')}
                                        </TableSortLabel>
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        {t('email')}
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        {t('mobile')}
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        <TableSortLabel
                                            direction={accessTypeSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    accessTypeSortDirection,
                                                    setAccessTypeSortDirection
                                                )
                                            }
                                        >
                                            {t('access_type')}
                                        </TableSortLabel>
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        {t('status')}
                                    </TableHeaderCellWithCheckbox>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedUserList?.map((rowUser) => (
                                    <TableRow
                                        style={{
                                            backgroundColor: checkedMap[
                                                rowUser.userId
                                            ]
                                                ? theme.palette.selectedRowColor
                                                : 'inherit'
                                        }}
                                        key={rowUser.userId}
                                        onClick={props.onRowClick(
                                            rowUser.userId
                                        )}
                                    >
                                        <TableCell>
                                            {rowUser.userId !== user.id &&
                                                renderCheckBox(rowUser)}
                                        </TableCell>
                                        <>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                onClick={() =>
                                                    navigateToUserDetails(
                                                        rowUser.userId
                                                    )
                                                }
                                            >
                                                {rowUser.firstName}{' '}
                                                {rowUser.lastName}
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    navigateToUserDetails(
                                                        rowUser.userId
                                                    )
                                                }
                                            >
                                                {rowUser.email}
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    navigateToUserDetails(
                                                        rowUser.userId
                                                    )
                                                }
                                            >
                                                {rowUser.phoneNumber}
                                            </TableCell>

                                            <TableCell
                                                onClick={() =>
                                                    navigateToUserDetails(
                                                        rowUser.userId
                                                    )
                                                }
                                            >
                                                {rowUser.role}
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    navigateToUserDetails(
                                                        rowUser.userId
                                                    )
                                                }
                                            >
                                                {getStatusView(
                                                    rowUser.userId,
                                                    rowUser.isActive,
                                                    rowUser.isDisabled
                                                )}
                                            </TableCell>
                                        </>

                                        <TableCellNoPadding>
                                            <Button
                                                color="secondary"
                                                aria-controls={
                                                    open
                                                        ? 'basic-menu'
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    open ? 'true' : undefined
                                                }
                                                onClick={(event) =>
                                                    handleMenuClick(
                                                        event,
                                                        rowUser
                                                    )
                                                }
                                            >
                                                <MoreVertIcon />
                                            </Button>
                                        </TableCellNoPadding>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Alert sx={{ marginTop: 1 }} severity="info">
                            The user list is empty.
                        </Alert>
                    )}

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem>
                            {getStatusButton(
                                rowUser?.userId!,
                                rowUser?.isActive!
                            )}
                        </MenuItem>
                        {user.role === UserRoles.SuperAdmin && (
                            <MenuItem
                                onClick={() => {
                                    handleDeleteUser?.(rowUser?.userId!);
                                    handleMenuClose();
                                }}
                            >
                                {t('remove')}
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                setEditUserOpen(true);
                                handleMenuClose();
                            }}
                            aria-label="edit user"
                        >
                            {t('edit_access')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                props.onSendActivationMailClicked(
                                    rowUser?.userId!,
                                    rowUser?.firstName + ' ' + rowUser?.lastName
                                );
                                handleMenuClose();
                            }}
                            aria-label="edit bank account details"
                        >
                            {t('send_activation_mail')}
                        </MenuItem>
                    </Menu>
                </TableContainer>
                <TablePagination
                    count={total!}
                    page={0}
                    onPageChange={() => {}}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage as any} //TODO: Fix this
                    rowsPerPageOptions={[
                        {
                            label: '16',
                            value: 16
                        },
                        {
                            label: '24',
                            value: 24
                        },
                        {
                            label: '32',
                            value: 32
                        },
                        {
                            label: 'All',
                            value: -1
                        }
                    ]}
                />
            </Card>

            <EditUserDialog
                dialogOpen={editUserOpen}
                onClose={() => setEditUserOpen(false)}
                rowData={editUserOpen && rowUser}
                refreshList={props.onReloadUser}
            />
        </>
    );
};
