import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer
} from '@mui/material';
import {
    TableCell,
    TableCellFirstCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellFirstCell,
    TableIconButtonCell,
    TableRow
} from '../../components/shared/styles/Table';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import { useContext, useEffect, useState } from 'react';
import {
    postAiiaRefreshPayment,
    getTransactionsHistory,
    getAiiaRetryAuthorizeUrl,
    setAiiaRetryErrorHandled,
    getUserTransactionsHistory,
    getCurrentCompany
} from '../../lib/slices/paymentsSlice';
import { Transaction } from '../../services/PaymentsServiceV2';
import { useLoader } from '../../contexts/loader/LoaderContext';
import Chip, { chipColors } from '../../components/shared/Chip';
import { formatDate, useFormatting } from '../../utils/formatting';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { TransactionStatus } from '../../models/transaction';
import { FETCH_STATE } from '../../lib/slices/types';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/user/UserContext';
import { TablePagination } from '../../components/shared/TablePagination';
import { useParams } from 'react-router-dom';
import { SMBold } from '../../typography/Typography';
import CardHeader from '../../components/shared/CardHeader';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../lib/constants';
import { approvalTypes } from '../../utils/constant';
import { X } from '@mui/icons-material';

interface UserTransactionsProps {
    userId: string;
}

const getStatusColor = (status: TransactionStatus) => {
    switch (status) {
        case TransactionStatus.PendingApproval:
            return 'success';
        case TransactionStatus.AwaitingBank:
        case TransactionStatus.AwaitingDeposit:
        case TransactionStatus.ActionInProgress:
        case TransactionStatus.Authorized:
            return 'warning';
        case TransactionStatus.Succeeded:
            return 'success';
        case TransactionStatus.Failed:
        case TransactionStatus.Rejected:
        case TransactionStatus.Expired:
            return 'error';
        default:
            return 'default';
    }
};

export const Status = ({ status }: any) => {
    return <Chip color={getStatusColor(status)} label={status} />;
};

export const UserTransactions = ({ userId }: UserTransactionsProps) => {
    const dispatch = useAppDispatch();

    const { setLoading } = useLoader();

    const { company } = useContext(UserContext);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const { setAlert } = useNotificationsSnackbar();
    const { t } = useTranslation();
    const { getBalanceLabel } = useFormatting();

    useEffect(() => {
        dispatch(
            getUserTransactionsHistory({
                userId,
                limit: rowsPerPage,
                offset: page * rowsPerPage
            })
        );
    }, [userId, page, rowsPerPage]);

    const {
        userTransactionHistory,
        aiiaRefreshPayment,
        aiiaRetryAuthorize,
        currentCompany
    } = usePayments();

    useEffect(() => {
        dispatch(getCurrentCompany());
    }, []);

    useEffect(() => {
        setLoading(aiiaRefreshPayment.status == FETCH_STATE.LOADING);
        if (aiiaRefreshPayment.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('status_refreshed'), 'success');
        } else if (aiiaRefreshPayment.status == FETCH_STATE.FAILED) {
            setAlert(t('status_refresh_failed'), 'error');
        }
    }, [aiiaRefreshPayment.status]);

    useEffect(() => {
        setLoading(aiiaRetryAuthorize.status == FETCH_STATE.LOADING);
        if (aiiaRetryAuthorize.status == FETCH_STATE.SUCCEEDED) {
            setAlert(t('success'), 'success');
        } else if (aiiaRetryAuthorize.status == FETCH_STATE.FAILED) {
            setAlert(t('transaction_retry_failed'), 'error');
            dispatch(setAiiaRetryErrorHandled());
        }
    }, [aiiaRetryAuthorize.status]);

    const onRefershButtonClicked = (row: Transaction) => {
        dispatch(postAiiaRefreshPayment(row.transactionId));
        dispatch(
            getTransactionsHistory({
                userId,
                limit: rowsPerPage,
                offset: page * rowsPerPage
            })
        );
    };

    const onRetryButtonClicked = (row: Transaction) => {
        dispatch(
            getAiiaRetryAuthorizeUrl({ transactionId: row.transactionId })
        );
    };

    const renderRefeshButton = (row: Transaction) => {
        return (
            <IconButton
                onClick={() => {
                    onRefershButtonClicked(row);
                }}
            >
                <RefreshIcon />
            </IconButton>
        );
    };

    const renderRetryButton = (row: Transaction) => {
        return (
            <IconButton onClick={() => onRetryButtonClicked(row)}>
                <PlayIcon />
            </IconButton>
        );
    };

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setRowsPerPage(rowsPerPage);
        setPage(0);
    };

    const { pageNumber } = useParams();

    useEffect(() => {
        if (pageNumber) {
            setPage(parseInt(pageNumber));
        }
    }, [pageNumber]);

    const getValidLastDate = () => {
        for (
            let i =
                userTransactionHistory?.transactions?.transactions?.length ?? 0;
            i >= 0;
            i--
        ) {
            if (
                userTransactionHistory?.transactions?.transactions[i]
                    ?.performedAt
            ) {
                return userTransactionHistory.transactions.transactions[i]
                    .performedAt;
            }
        }

        if (
            userTransactionHistory?.transactions?.transactions?.length ??
            0 >= 1
        ) {
            return userTransactionHistory.transactions?.transactions[
                userTransactionHistory?.transactions?.transactions?.length - 1
            ].createdAt;
        }
    };

    const getValidFirstDate = () => {
        for (
            let i = 0;
            i <
            (userTransactionHistory?.transactions?.transactions?.length ?? 0);
            i++
        ) {
            if (
                userTransactionHistory?.transactions?.transactions[i]
                    ?.performedAt
            ) {
                return userTransactionHistory.transactions.transactions[i]
                    .performedAt;
            }
        }
        if (
            userTransactionHistory?.transactions?.transactions?.length ??
            0 >= 1
        ) {
            return userTransactionHistory.transactions?.transactions[0]
                .createdAt;
        }
    };

    return (
        <>
            {userTransactionHistory?.transactions?.transactions?.length !=
                0 && (
                <TableContainer component={Paper}>
                    <CardHeader
                        label={`${dayjs(getValidFirstDate()).format(
                            DATE_FORMAT.DAY_MONTH_YEAR
                        )} - ${dayjs(getValidLastDate()).format(
                            DATE_FORMAT.DAY_MONTH_YEAR
                        )}`}
                        description={` `}
                        chip={
                            <Chip
                                icon={<></>}
                                label={
                                    <SMBold color={chipColors.success.text}>
                                        {
                                            userTransactionHistory?.transactions
                                                ?.total
                                        }
                                    </SMBold>
                                }
                                color={'success'}
                            />
                        }
                    />
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCellFirstCell>
                                    {t('date')}
                                </TableHeaderCellFirstCell>
                                <TableHeaderCell>
                                    {t('description')}
                                </TableHeaderCell>
                                <TableHeaderCell>{t('status')}</TableHeaderCell>
                                <TableHeaderCell>
                                    {t('approved_by')}
                                </TableHeaderCell>
                                <TableHeaderCell align="right">
                                    {t('amount')}
                                </TableHeaderCell>
                                {currentCompany.company?.paymentProvider ==
                                    'Aiia' && (
                                    <>
                                        <TableHeaderCell>
                                            {t('refresh')}
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            {t('retry')}
                                        </TableHeaderCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {userTransactionHistory?.transactions?.transactions?.map(
                                (row) => {
                                    return (
                                        <TableRow key={row.transactionId}>
                                            <TableCellFirstCell
                                                style={{
                                                    width: '140px'
                                                }}
                                            >
                                                {row.performedAt
                                                    ? formatDate(
                                                          new Date(
                                                              row.performedAt
                                                          )
                                                      )
                                                    : formatDate(
                                                          new Date(
                                                              row.createdAt
                                                          )
                                                      )}
                                            </TableCellFirstCell>
                                            <TableCell
                                                style={{
                                                    width: '400px'
                                                }}
                                            >
                                                {row.transactionType}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    justifyContent: 'center',
                                                    width: '138px'
                                                }}
                                            >
                                                <Status status={row.status} />
                                            </TableCell>
                                            <TableCell>
                                                {row.approvalType ===
                                                    approvalTypes.manual && (
                                                    <>
                                                        {row.executantAdminName}
                                                    </>
                                                )}
                                                {row.approvalType ===
                                                    approvalTypes.automatic && (
                                                    <>
                                                        {t(
                                                            'automatic_approval'
                                                        )}
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {getBalanceLabel(
                                                    row.amount,
                                                    company.currency,
                                                    undefined,
                                                    undefined,
                                                    true
                                                )}
                                            </TableCell>
                                            {currentCompany.company
                                                ?.paymentProvider == 'Aiia' && (
                                                <>
                                                    <TableIconButtonCell>
                                                        {row.status ==
                                                            'Authorized' &&
                                                            renderRefeshButton(
                                                                row
                                                            )}
                                                    </TableIconButtonCell>
                                                    <TableIconButtonCell>
                                                        {row.status ==
                                                            'Failed' &&
                                                            renderRetryButton(
                                                                row
                                                            )}
                                                    </TableIconButtonCell>
                                                </>
                                            )}
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        page={0}
                        rowsPerPageOptions={[
                            {
                                label: '7',
                                value: 7
                            },
                            {
                                label: '16',
                                value: 16
                            },
                            {
                                label: '32',
                                value: 32
                            },
                            {
                                label: 'All',
                                value: -1
                            }
                        ]}
                        count={userTransactionHistory?.transactions?.total ?? 0}
                        rowsPerPage={rowsPerPage}
                        onPageChange={() => {}}
                        onRowsPerPageChange={handleChangeRowsPerPage as any}
                    />
                </TableContainer>
            )}
        </>
    );
};
