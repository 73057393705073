import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import {
    PaymentsUser,
    UpdateUserBankAccountDetailsRequest
} from '../../../models/payment';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';

type UpdateBankAccountDialogProps = {
    userId?: string;
    open: boolean;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
    onClose: () => void;
    onSubmit: (request: UpdateUserBankAccountDetailsRequest) => Promise<void>;
};

export const UpdateBankAccountDialog = (
    props: UpdateBankAccountDialogProps
) => {
    const [bankCode, setBankCode] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<string>('');
    const [user, setUser] = useState<PaymentsUser>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();

    const onSubmit = () => {
        if (!props.userId) return;

        props.onSubmit({
            bankCode: bankCode,
            bankAccountNumber: bankAccountNumber,
            userId: props.userId
        });
    };

    useEffect(() => {
        if (!props.userId) return;

        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getUser(props.userId)
            .then((response: any) => {
                let responseUser = response.data as PaymentsUser;
                setUser(responseUser);
                setBankCode(responseUser.bankCode);
                setBankAccountNumber(responseUser.bankAccountNumber);
            })
            .catch(backendErrorHandler('Error fetching users: '))
            .finally(() => {
                setLoading(false);
            });
    }, [props.userId, backendErrorHandler, setLoading]);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Edit bank account details for {user?.email}
            </DialogTitle>
            <DialogContent>
                <TextField
                    name="bank_code"
                    label="Bank code"
                    fullWidth
                    variant="standard"
                    value={bankCode}
                    onChange={(e) => {
                        setBankCode(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('bankCode')
                        );
                    }}
                    error={props.validator.hasError('bankCode')}
                    helperText={props.validator.getError('bankCode')}
                />

                <TextField
                    name="bank_account_number"
                    label="Bank account number"
                    fullWidth
                    variant="standard"
                    value={bankAccountNumber}
                    onChange={(e) => {
                        setBankAccountNumber(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('bankAccountNumber')
                        );
                    }}
                    error={props.validator.hasError('bankAccountNumber')}
                    helperText={props.validator.getError('bankAccountNumber')}
                />

                <Alert severity="info" sx={{ marginTop: '1rem' }}>
                    Note: after you update the employee's bank account details
                    manually, they will no longer be updated during import from
                    salary provider.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" onClick={onSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
