import { useState, useEffect, useCallback } from 'react';
import { Alert } from '@mui/material';
import { debounce } from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import { UserTableType, UsersTable } from '../UsersTable';
import { SettingsUsersTable } from '../SettingsUserTable';
import { AddUserForm } from '../add/AddUserForm';
import { UpdateUserForm } from '../update/UpdateUserForm';
import { UpdateBankAccountForm } from '../bankAccountDetails/UpdateBankAccountForm';
import {
    UserDetailedResponse,
    GetIntectUsersDetailedReqest,
    IntectUserDetailed,
    CompanyUsersResponse
} from '../../../models/payment';
import { useErrorHandling } from '../../../utils/errorHandling';
import { IntectUsersFilters } from './IntectUsersFilters';
import { useEmployeesOverview } from '../EmployeesOverview';
import {
    WithConfirmation,
    WithConfirmationWrappedComponentProps
} from '../../../utils/withConfirmation';
import { useEmployeeStatusConfirmation } from '../useEmployeeStatusConfirmation';
import useUserActiveFilter from '../useUserActiveFilter';
import { useAppDispatch, usePayments } from '../../../lib/hooks';
import { FETCH_STATE } from '../../../lib/slices/types';
import { checkValueAndCall } from '../../../lib/functionUtil';
import { getFilterToParams } from '../UserActiveFilter';
import {
    getIntectUsersDetailed,
    getCompaniesUsers
} from '../../../lib/slices/paymentsSlice';
import { useParams } from 'react-router-dom';
import { setLoader } from '../../../lib/slices/globalLoaderSlice';
import {
    getRowsPerPageForUserTable,
    saveRowsPerPageForUserTable
} from '../../../lib/localStorage';
interface IntectEmployeesProps extends WithConfirmationWrappedComponentProps {
    displaySection: boolean;
}

export const IntectEmployeesOverview = WithConfirmation(
    (props: IntectEmployeesProps) => {
        const { showConfirmDialog, displaySection } = props;
        const [usersDetailed, setUsersDetailed] =
            useState<UserDetailedResponse<IntectUserDetailed>>();
        const [companyUsersDetailed, setCompanyUsersDetailed] =
            useState<CompanyUsersResponse>();
        const [error, setError] = useState<string>();
        const [updateUserId, setUpdateUserId] = useState<string>();
        const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
        const [updateBankAccountUserId, setUpdateBankAccountUserId] =
            useState<string>();
        const [
            updateBankAccountUserDialogOpen,
            setUpdateBankAccountUserDialogOpen
        ] = useState(false);

        const [checked, setChecked] = useState<string[]>([]);
        const [selectedDepartments, setSelectedDepartments] = useState<
            string[]
        >([]);
        const [selectedPandayEmployeeIds, setPandayEmployeeTypesIds] = useState<
            string[]
        >([]);
        const [selectedEmploymentTemplates, setSelectedEmploymentTemplates] =
            useState<string[]>([]);
        const [selectedActiveType, setSelectedActiveType] =
            useState<string>('');
        const [username, setUsername] = useState<string>('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(16);
        const { backendErrorHandler } = useErrorHandling();
        const {
            onRowClick,
            onCheckAllClick,
            allUsersChecked,
            onCheckAllCompanyUsersClick,
            allCompanyUsersChecked
        } = useEmployeesOverview();

        const { filteredUserDetailsList, renderOptions: renderActiveOptions } =
            useUserActiveFilter(usersDetailed);

        const { filteredUserDetailsList: filteredCompanyAdminDetailsList } =
            useUserActiveFilter(companyUsersDetailed);

        const dispatch = useAppDispatch();

        const { intectUsersDetailed, companyUsers } = usePayments();

        useEffect(() => {
            dispatch(
                setLoader(intectUsersDetailed.status == FETCH_STATE.LOADING)
            );

            if (intectUsersDetailed.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Users could not be fetched');
                });
            }

            if (intectUsersDetailed.status == FETCH_STATE.SUCCEEDED) {
                setUsersDetailed(intectUsersDetailed.usersResponse);
            }
        }, [intectUsersDetailed]);

        const reloadUsersPaymentsService = useCallback(() => {
            let activeStatus = getFilterToParams(selectedActiveType);
            const request: GetIntectUsersDetailedReqest = {
                departmentIds: selectedDepartments,
                employmentTemplateIds: selectedEmploymentTemplates,
                plandayEmployeeTypeIds: selectedPandayEmployeeIds,
                username: username,
                ...activeStatus,
                limit: rowsPerPage > 0 ? rowsPerPage : 0, // can be set to -1 to show all
                offset: page * rowsPerPage
            };

            dispatch(getIntectUsersDetailed(request));
        }, [
            backendErrorHandler,
            selectedDepartments,
            selectedEmploymentTemplates,
            selectedActiveType,
            username,
            page,
            rowsPerPage,
            selectedPandayEmployeeIds
        ]);

        let debouncedReload = debounce(() => {
            reloadUsersPaymentsService();
        }, 100);

        const reloadUsers = useCallback(() => {
            debouncedReload?.cancel();
            debouncedReload();
            setChecked([]);
        }, [reloadUsersPaymentsService]);

        useEffect(() => {
            dispatch(setLoader(companyUsers.status === FETCH_STATE.LOADING));
            if (companyUsers.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Company Admins could not be fetched');
                });
            }
            if (companyUsers.status == FETCH_STATE.SUCCEEDED) {
                setCompanyUsersDetailed(companyUsers?.companySettingsUsers);
            }
        }, [companyUsers]);

        const reloadCompanyUsersService = useCallback(() => {
            dispatch(
                getCompaniesUsers({
                    limit: rowsPerPage > 0 ? rowsPerPage : 0,
                    offset: page * rowsPerPage
                })
            );
        }, [page, rowsPerPage]);

        let debouncedCompanyUsersReload = debounce(() => {
            reloadCompanyUsersService();
        }, 100);

        const reloadCompanyUsers = useCallback(() => {
            debouncedCompanyUsersReload?.cancel();
            debouncedCompanyUsersReload();
            setChecked([]);
        }, [reloadCompanyUsersService]);

        const {
            handleBulkEnable,
            handleBulkDisable,
            handleStatusChange,
            handleRoleToggleChange,
            sendActivationEmail,
            handleDeleteUser
        } = useEmployeeStatusConfirmation(
            showConfirmDialog,
            reloadUsers,
            reloadCompanyUsers,
            displaySection
        );

        useEffect(() => {
            reloadUsers();
        }, [reloadUsers]);

        useEffect(() => {
            reloadCompanyUsers();
        }, [reloadCompanyUsers]);

        const onEditClick = (userId: string) => () => {
            setUpdateUserId(userId);
            setUpdateUserDialogOpen(true);
        };

        const onUpdateBankAccountDetailsClick = (userId: string) => () => {
            setUpdateBankAccountUserId(userId);
            setUpdateBankAccountUserDialogOpen(true);
        };

        const handleDepartmentChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedDepartments);
        };

        const handlePlandayEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setPandayEmployeeTypesIds);
        };

        const handleEmploymentTemplateChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedEmploymentTemplates);
        };

        const handleSetSelectedActiveType = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedActiveType);
        };

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
        ) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (rowsPerPage: number) => {
            setRowsPerPage(rowsPerPage);
            saveRowsPerPageForUserTable(rowsPerPage);
            setPage(0);
        };

        useEffect(() => {
            let rowsPerPage = getRowsPerPageForUserTable();
            setRowsPerPage(rowsPerPage);
        }, []);

        const handleSentActivationEmail = (userId: string, name: string) => {
            sendActivationEmail(userId, name, setError);
        };

        const { pageNumber } = useParams();

        useEffect(() => {
            if (pageNumber) {
                setPage(parseInt(pageNumber));
            }
        }, [pageNumber]);

        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {usersDetailed && (
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: -80
                            }}
                        >
                            {!displaySection && (
                                <AddUserForm onUserListChange={reloadUsers} />
                            )}
                        </div>
                        {!displaySection && (
                            <IntectUsersFilters
                                renderActiveOptions={renderActiveOptions}
                                onPlanDayEmployeeTypeChange={
                                    handlePlandayEmployeeTypesChange
                                }
                                selectedPlanDayEmployeeTypes={
                                    selectedPandayEmployeeIds
                                }
                                selectedDepartments={selectedDepartments}
                                onDepartmentChange={handleDepartmentChange}
                                selectedEmploymentTemplates={
                                    selectedEmploymentTemplates
                                }
                                onEmploymentTemplateChange={
                                    handleEmploymentTemplateChange
                                }
                                username={username}
                                onUsernameChange={setUsername}
                                selectedActiveType={selectedActiveType}
                                onActiveTypeChange={handleSetSelectedActiveType}
                            />
                        )}

                        <FormGroup sx={{ marginBottom: '1rem' }}>
                            {!displaySection ? (
                                <UsersTable
                                    checked={checked}
                                    handleDeleteUser={(userId: string) =>
                                        handleDeleteUser(userId, reloadUsers)
                                    }
                                    allChecked={allUsersChecked(
                                        filteredUserDetailsList,
                                        checked
                                    )}
                                    onRowClick={(userId: string) =>
                                        onRowClick(userId, checked, setChecked)
                                    }
                                    onCheckAllClick={() =>
                                        onCheckAllClick(
                                            filteredUserDetailsList,
                                            checked,
                                            setChecked
                                        )
                                    }
                                    onStatusClick={(
                                        userId: string,
                                        isActive: boolean
                                    ) =>
                                        handleStatusChange(
                                            userId,
                                            isActive,
                                            reloadUsers
                                        )
                                    }
                                    onRoleToggleChange={(
                                        userId: string,
                                        isAdmin: boolean
                                    ) =>
                                        handleRoleToggleChange(
                                            userId,
                                            isAdmin,
                                            reloadUsers
                                        )
                                    }
                                    onEditClick={onEditClick}
                                    onUpdateBankAccountDetailsClick={
                                        onUpdateBankAccountDetailsClick
                                    }
                                    onSendActivationMailClicked={
                                        handleSentActivationEmail
                                    }
                                    // usersBase={filteredUsers}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    usersDetailed={filteredUserDetailsList}
                                    rowsPerPage={rowsPerPage}
                                    userTableType={UserTableType.Intect}
                                    total={
                                        intectUsersDetailed?.usersResponse
                                            ?.filteredTotal
                                    }
                                    handleBulkEnable={handleBulkEnable}
                                    handleBulkDisable={handleBulkDisable}
                                />
                            ) : (
                                <SettingsUsersTable
                                    addUserForm={
                                        <AddUserForm
                                            onUserListChange={reloadUsers}
                                        />
                                    }
                                    checked={checked}
                                    allChecked={allCompanyUsersChecked(
                                        filteredCompanyAdminDetailsList,
                                        checked
                                    )}
                                    onRowClick={(userId: string) =>
                                        onRowClick(userId, checked, setChecked)
                                    }
                                    onCheckAllClick={() =>
                                        onCheckAllCompanyUsersClick(
                                            filteredCompanyAdminDetailsList,
                                            checked,
                                            setChecked
                                        )
                                    }
                                    onStatusClick={(
                                        userId: string,
                                        isActive: boolean
                                    ) =>
                                        handleStatusChange(
                                            userId,
                                            isActive,
                                            reloadCompanyUsers
                                        )
                                    }
                                    onRoleToggleChange={(
                                        userId: string,
                                        isAdmin: boolean
                                    ) =>
                                        handleRoleToggleChange(
                                            userId,
                                            isAdmin,
                                            reloadCompanyUsers
                                        )
                                    }
                                    onReloadUser={reloadCompanyUsers}
                                    onUpdateBankAccountDetailsClick={
                                        onUpdateBankAccountDetailsClick
                                    }
                                    onSendActivationMailClicked={
                                        handleSentActivationEmail
                                    }
                                    // usersBase={filteredUsers}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    usersDetailed={
                                        filteredCompanyAdminDetailsList
                                    }
                                    rowsPerPage={rowsPerPage}
                                    userTableType={UserTableType.Intect}
                                    total={
                                        intectUsersDetailed?.usersResponse
                                            ?.filteredTotal
                                    }
                                    handleBulkEnable={handleBulkEnable}
                                    handleBulkDisable={handleBulkDisable}
                                    handleDeleteUser={(userId: string) =>
                                        handleDeleteUser(
                                            userId,
                                            reloadCompanyUsers
                                        )
                                    }
                                />
                            )}
                        </FormGroup>
                    </div>
                )}

                <UpdateUserForm
                    onUserListChange={reloadUsers}
                    userId={updateUserId}
                    open={updateUserDialogOpen}
                    setOpen={(open: boolean) => setUpdateUserDialogOpen(open)}
                />

                <UpdateBankAccountForm
                    onUserListChange={reloadUsers}
                    userId={updateBankAccountUserId}
                    open={updateBankAccountUserDialogOpen}
                    setOpen={(open: boolean) =>
                        setUpdateBankAccountUserDialogOpen(open)
                    }
                />
            </>
        );
    }
);
