import { Table, TableBody, TableContainer, Alert } from '@mui/material';
import {
    TableCell,
    TableHeader,
    TableHeaderCellWithCheckbox,
    TableRow,
    TableSortLabel
} from '../../components/shared/styles/Table';
import { CompanyAccountsResponse } from '../../models/company';
import { toDecimal } from '../../utils/formatting';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CompanyAccountsTableProps = {
    companyAccounts: CompanyAccountsResponse;
};

type SortingType = 'asc' | 'desc' | undefined;

export const CompanyAccountsTable = (props: CompanyAccountsTableProps) => {
    const { t } = useTranslation();

    const [nameSortDirection, setNameSortDirection] =
        useState<SortingType>(undefined);

    const sortedUserList = useMemo(() => {
        let list = [...props.companyAccounts?.companyAccounts].sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        const sortArr = [
            {
                key: 'name',
                direction: nameSortDirection
            }
        ];

        console.log(sortArr, 'sortArr');
        sortArr.forEach((sortDirection) => {
            if (sortDirection.direction) {
                list.sort((a: any, b: any) => {
                    if (a[sortDirection.key] && b[sortDirection.key]) {
                        return (
                            a[sortDirection.key]!.toString().localeCompare(
                                b[sortDirection.key!]!.toString()
                            ) * (sortDirection.direction === 'asc' ? 1 : -1)
                        );
                    }
                    return 0;
                });
            }
        });

        return list;
    }, [props.companyAccounts?.companyAccounts, nameSortDirection]);

    const handleSort = useCallback(
        (key: SortingType, func: Function) => {
            setNameSortDirection(undefined);

            key === 'asc' ? func('desc') : func('asc');
        },
        [props.companyAccounts?.companyAccounts, nameSortDirection]
    );

    return (
        <>
            {props.companyAccounts.companyAccounts.length === 0 ? (
                <Alert severity="info">
                    There are no companies with Lump Sum payment.
                </Alert>
            ) : (
                <TableContainer sx={{ marginTop: 5 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCellWithCheckbox>
                                    <TableSortLabel
                                        direction={nameSortDirection}
                                        onClick={() =>
                                            handleSort(
                                                nameSortDirection,
                                                setNameSortDirection
                                            )
                                        }
                                    >
                                        {t('company')}
                                    </TableSortLabel>
                                </TableHeaderCellWithCheckbox>
                                <TableHeaderCellWithCheckbox>
                                    {t('awaiting_approval')}
                                </TableHeaderCellWithCheckbox>
                                <TableHeaderCellWithCheckbox align="right">
                                    {t('balance')}
                                </TableHeaderCellWithCheckbox>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {sortedUserList.map((companyAccount) => (
                                <TableRow key={companyAccount.companyId}>
                                    <TableCell component="th" scope="row">
                                        {companyAccount.name}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            companyAccount.pendingTransactionsCount
                                        }
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>
                                        {toDecimal(companyAccount.balance)}{' '}
                                        {companyAccount.currency}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
