import styled from '@emotion/styled';
import { useTheme, Radio as MuiRadio, RadioProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface ExtraRadioProps extends RadioProps {
    customRadio?: boolean;
}

export const Icon = styled.div`
    width: 16px;
    height: 16px;
    border: 1px solid #eaecf0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconCheckedOuter = styled(Icon)`
    border: 1px solid #1570ef;
    background: #1570ef;
`;

const CustomIconCheckedOuter = styled(Icon)`
    border: 1px solid #089855;
    background: #ecfdf3;
`;

const IconChecked = () => {
    return (
        <IconCheckedOuter>
            <CheckIcon
                style={{
                    color: 'white',
                    width: '10px',
                    height: '14px',
                    strokeWidth: '2px',
                    stroke: 'white'
                }}
            />
        </IconCheckedOuter>
    );
};

const CustomIconChecked = () => {
    const theme = useTheme();
    return (
        <CustomIconCheckedOuter>
            <FiberManualRecordIcon
                style={{
                    color: theme.palette.text.success,
                    width: '11px',
                    height: '11px'
                }}
            />
        </CustomIconCheckedOuter>
    );
};

export const RadioButton = (props: ExtraRadioProps) => {
    return (
        <MuiRadio
            icon={<Icon />}
            checkedIcon={
                props?.customRadio ? <CustomIconChecked /> : <IconChecked />
            }
            {...props}
        />
    );
};
