import { InputAdornment, TextField, debounce } from '@mui/material';
import { useEffect, useState } from 'react';
import { SearchIcon } from '../../icons/SearchIcon';
import styled from '@emotion/styled';

export type SearchInputProps = {
    // text: string;
    onDebouncedTextChange: (name: string) => void;
};

const NoStyleInput = styled.input`
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    &:focus {
        outline: none;
        border: none;
    }
    ::placeholder {
        color: #344054;
        font-size: 14px;
        line-height: 20px;
    }
`;

const SearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 36px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
`;

export const SearchInput = ({ onDebouncedTextChange }: SearchInputProps) => {
    const [text, setText] = useState('');

    useEffect(() => {
        debounce(() => {
            onDebouncedTextChange(text);
        }, 400)();
    }, [text]);

    return (
        <SearchInputWrapper>
            <SearchIcon style={{ marginRight: '8px' }} />
            <NoStyleInput
                placeholder="Search"
                onChange={(e) => setText(e.currentTarget.value)}
            ></NoStyleInput>
        </SearchInputWrapper>
    );
};
