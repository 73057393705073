import {
    TableCell as MuiTableCell,
    TableHead,
    TableFooter as TableFoot,
    styled,
    TableRow as MuiTableRow,
    TableSortLabel as MuiTableSortLabel,
    TableSortLabelProps
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const TableHeader = styled(TableHead)`
    background-color: #f9fafb;
    border-top: 1px solid var(--Gray-300, #d0d5dd); //TODO: move color to theme
    height: 32px;
`;

export const TableFooter = styled(TableFoot)`
    background-color: #f2f4f7;
    border-top: 1px solid var(--Gray-300, #d0d5dd); //TODO: move color to theme
    height: 32px;
    height: 48px;
    font-weight: 600;
`;

export const TableCell = styled(MuiTableCell)`
    padding: 16px 8px 12px 24px;
    height: 40px;
`;

export const TableCellNoBorder = styled(TableCell)`
    border-bottom: none;
    padding: 12px, 8px, 12px, 8px;
`;

export const TableHeaderCell = styled(MuiTableCell)`
    padding: 0px 8px 0px 24px;
    height: 32px;
    font-weight: 600;
`;

export const TableHeaderCellWithCheckbox = styled(MuiTableCell)`
    padding: 0px 8px 0px 18px;
    height: 32px;
    font-weight: 600;
`;

export const TableFooterCell = styled(MuiTableCell)`
    padding: 0px 8px 0px 24px;
    height: 32px;
    font-weight: 600;
`;

export const TableHeaderCellFirstCell = styled(TableHeaderCell)`
    padding-left: 20px;
`;

export const TableCellFirstCell = styled(TableCell)`
    padding-left: 20px;
`;

export const TableCellNoPadding = styled(MuiTableCell)`
    & {
        padding: 0px;
    }
`;

export const TableIconButtonCell = styled(MuiTableCell)`
    & {
        width: 8px;
        text-align: center;
        padding: 0px 0px 0px 8px;
    }
`;

export const _MuiTableSortLabel = (props: TableSortLabelProps) => {
    return (
        <MuiTableSortLabel
            style={{ width: '100%' }}
            IconComponent={KeyboardArrowDownIcon}
            {...props}
        />
    );
};

export const TableSortLabel = styled(_MuiTableSortLabel)`
    padding: 0px 8px 0px 4px;
    height: 32px;
    font-weight: 600;
    & svg {
        right: 0px;
        position: absolute;
    }
`;

export const TableRow = styled(MuiTableRow)``; //TODO: add background on hover;
