import { Typography, styled } from '@mui/material';

export const TEXT_SIZE = {
    XS: '12px', // new size based on design system
    SMALL: '12px',
    MEDIUM_SMALL: '14px',
    MEDIUM: '16px',
    LARGE_MEDIUM: '18px',
    LARGE: '24px',
    MEDIUM_LARGE: '30px',
    XLARGE: '32px',
    XXLARGE: '40px'
};

export const FONT_WEIGHT = {
    LIGHT: '300',
    REGULAR: '400',
    MEDIUM: '500',
    SEMI_BOLD: '600',
    BOLD: '700'
};

export const LINE_HEIGHT = {
    SMALL: '16px',
    MEDIUM_SMALL: '20px',
    MEDIUM: '24px',
    LARGE_MEDIUM: '28px',
    LARGE: '32px',
    XLARGE: '40px',
    XXLARGE: '48px'
};

export const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '700'
}));

export const Heading = styled(Typography)(
    ({ fontSize, fontWeight, lineHeight }) => ({
        fontSize: (fontSize as string) ?? TEXT_SIZE.MEDIUM,
        fontWeight: (fontWeight as string) ?? FONT_WEIGHT.SEMI_BOLD,
        lineHeight: (lineHeight as string) ?? LINE_HEIGHT.MEDIUM
    })
);

export const Caption = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    color: theme.palette.text.secondary
}));

export const SMBold = styled(Typography)(
    ({ theme, fontSize, color, fontWeight }) => ({
        fontSize: fontSize?.toString() ?? '12px',
        fontWeight: fontWeight?.toString() ?? '700',
        lineHeight: TEXT_SIZE.SMALL,
        color: color?.toString() ?? theme.palette.text.primary
    })
);

export const SMSemiBold = styled(SMBold)(({ fontWeight }) => ({
    fontWeight: fontWeight?.toString() ?? '600'
}));

export const XSMedium = styled(Typography)(({ theme, color }) => ({
    fontSize: TEXT_SIZE.XS,
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: '18px',
    color: color?.toString() ?? theme.palette.text.primary
}));

export const PageHeading = styled(Typography)(() => ({
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: '40px'
}));

export const PageSubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.text.secondary
}));

export const Title = styled(Typography)(
    ({ fontSize, fontWeight, lineHeight }) => ({
        fontSize: (fontSize as string) ?? TEXT_SIZE.MEDIUM_SMALL,
        fontWeight: (fontWeight as string) ?? FONT_WEIGHT.MEDIUM,
        lineHeight: (lineHeight as string) ?? LINE_HEIGHT.MEDIUM_SMALL
    })
);

export const SubTitle = styled(Typography)(
    ({ fontSize, fontWeight, lineHeight, color }) => ({
        fontSize: (fontSize as string) ?? TEXT_SIZE.MEDIUM_SMALL,
        fontWeight: (fontWeight as string) ?? FONT_WEIGHT.REGULAR,
        lineHeight: (lineHeight as string) ?? LINE_HEIGHT.MEDIUM_SMALL,
        color: '#667085'
    })
);
