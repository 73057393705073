import { useCallback, useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { ZenegyEmployeesOverview } from './zenegy/ZenegyEmployeesOverview';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { Alert, Stack } from '@mui/material';
import { IntectEmployeesOverview } from './intect/IntectEmployeesOverview';
import {
    PageHeading,
    PageSubHeading,
    SMBold
} from '../../typography/Typography';
import { useTranslation } from 'react-i18next';
import { usePayments } from '../../lib/hooks';
import Chip, { chipColors } from '../../components/shared/Chip';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const UsersPage = () => {
    const [companyPaymentDetails, setCompanyPaymentDetails] =
        useState<CompanyPaymentDetailsResponse>();
    const [error, setError] = useState<string>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();

    useEffect(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompanyPaymentDetails(response.data);
            })
            .catch(
                backendErrorHandler(undefined, () => {
                    setError('Could not load current company payment details');
                })
            )
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const { t } = useTranslation();

    const { zenergyUsersDetailed, intectUsersDetailed } = usePayments();

    const renderPageHeading = useCallback((total?: number) => {
        return (
            <Stack direction={'row'} alignItems={'center'}>
                <PageHeading mr={1}>{t('employees')}</PageHeading>{' '}
                <Chip
                    color={'success'}
                    label={
                        <SMBold color={chipColors.success.text}>{total}</SMBold>
                    }
                />
            </Stack>
        );
    }, []);

    return (
        <ContentWrapper>
            <>
                {companyPaymentDetails &&
                    companyPaymentDetails.salaryProvider === 'Zenegy' && (
                        <>
                            {renderPageHeading(
                                zenergyUsersDetailed.usersResponse?.total
                            )}
                            <PageSubHeading>
                                {t('employeesSubHeading')}
                            </PageSubHeading>
                            <ZenegyEmployeesOverview />
                        </>
                    )}
                {companyPaymentDetails &&
                    companyPaymentDetails.salaryProvider === 'Intect' && (
                        <>
                            {renderPageHeading(
                                intectUsersDetailed.usersResponse?.total
                            )}
                            <PageSubHeading>
                                {t('employeesSubHeading')}
                            </PageSubHeading>
                            <IntectEmployeesOverview />
                        </>
                    )}
                {error && <Alert severity="error">{error}</Alert>}
            </>
        </ContentWrapper>
    );
};
