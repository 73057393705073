import { useEffect, useState, useCallback } from 'react';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { BalanceDetails } from '@instapaid/shared-logic';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export interface Balance {
    balance: number;
    details: BalanceDetails;
    error: string | null;
}

export const useBalance = (userId: string) => {
    const { setLoading } = useLoader();
    const [balance, setBalance] = useState<Balance>();

    const { backendErrorHandler } = useErrorHandling();
    const dispatch = useAppDispatch();

    const getBalance = useCallback(() => {
        dispatch(setLoader(true));
        PaymentsAPIServiceV2.getInstance()
            .getUserBalance(userId)
            .then((response: any) => {
                setBalance(response.data);
            })
            .catch(backendErrorHandler('Error fetching balance: '))
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        getBalance();
    }, []);

    return {
        balance
    } as const;
};
