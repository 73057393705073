import { Dialog, DialogContent, DialogTitle, FormGroup } from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';
import { useState } from 'react';
import { formatDate } from '../../utils/formatting';
import { TermsVersionsResponse } from '../../models/payment';
import { UpdateConsentsRequest } from '../../models/company';
import { ConsentFormActions } from './ConsentFormActions';
import { ConsentCheckbox } from './ConsentCheckbox';
import { ExitImpersonationAlert } from '../ExitImpersonationAlert';

export type FirstTimeConsentProps = {
    termsVersions: TermsVersionsResponse;
    updateConsents: (request: UpdateConsentsRequest) => void;
    postponeConsents: () => void;
};

export const FirstTimeConsent = (props: FirstTimeConsentProps) => {
    const { user, company } = useUser();
    const [commercialAndDataConsent, setCommercialAndDataConsent] =
        useState(false);
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const commercialTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Commercial'
    );
    const dataTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Data'
    );

    return (
        <Dialog open={true} fullWidth={true} maxWidth="lg">
            <DialogTitle>
                Welcome to InstaPaid, {user.firstName} {user.lastName}!
            </DialogTitle>
            <DialogContent sx={{ color: 'black' }}>
                <ExitImpersonationAlert />

                <p>
                    We're thrilled to welcome you and {company.name}. This is
                    your company's dashboard, where you'll have access to our
                    services, tailored to fit your needs.
                </p>
                {company.endOfTrial !== null && (
                    <p>
                        <b>
                            <i>
                                We're excited to present an exclusive
                                introductory offer, allowing you to enjoy and
                                assess our services entirely free of charge and
                                without commitment until{' '}
                                {formatDate(company.endOfTrial)}.
                            </i>
                        </b>
                    </p>
                )}
                <p>
                    If you have any questions or need assistance, our dedicated
                    support team is always ready to help. Don't hesitate to
                    reach out.
                </p>

                <FormGroup>
                    <ConsentCheckbox
                        checked={commercialAndDataConsent}
                        setChecked={(checked) =>
                            setCommercialAndDataConsent(checked)
                        }
                        label={
                            <>
                                <span>I have read and accepted the </span>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={commercialTerms?.url}
                                >
                                    license agreement
                                </a>
                                <span> and the </span>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={dataTerms?.url}
                                >
                                    data processing agreement
                                </a>
                                .
                            </>
                        }
                    />

                    <ConsentCheckbox
                        checked={newsletterConsent}
                        setChecked={(checked) => setNewsletterConsent(checked)}
                        label="I hereby give my consent to receive newsletters and information from InstaPaid via email or through the InstaPaid application regarding InstaPaid's product range, such as updates and new features in the InstaPaid application. InstaPaid will process my information, including name, contact details, and company, for this purpose."
                    />
                </FormGroup>

                <p>
                    Thank you for choosing InstaPaid. Let's embark on this
                    exciting journey together!
                </p>
                <p>Warm Regards,</p>
                <p>
                    Michael Kayser
                    <br />
                    CEO
                </p>
            </DialogContent>

            <ConsentFormActions
                commercialConsent={commercialAndDataConsent}
                dataConsent={commercialAndDataConsent}
                newsletterConsent={newsletterConsent}
                postponeConsents={props.postponeConsents}
                updateConsents={props.updateConsents}
                termsVersions={props.termsVersions}
            />
        </Dialog>
    );
};
