import { Dialog, DialogContent, DialogTitle, FormGroup } from '@mui/material';
import { useState } from 'react';
import { TermsVersionsResponse } from '../../models/payment';
import { UpdateConsentsRequest } from '../../models/company';
import { useUser } from '../../contexts/user/UserContext';
import { ConsentFormActions } from './ConsentFormActions';
import { ConsentCheckbox } from './ConsentCheckbox';
import { ExitImpersonationAlert } from '../ExitImpersonationAlert';

export type UpdatedConsentProps = {
    termsVersions: TermsVersionsResponse;
    updateConsents: (request: UpdateConsentsRequest) => void;
    postponeConsents: () => void;
};

export const UpdatedConsent = (props: UpdatedConsentProps) => {
    const { user } = useUser();
    const [commercialConsent, setCommercialConsent] = useState(false);
    const [dataConsent, setDataConsent] = useState(false);
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const commercialTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Commercial'
    );
    const dataTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Data'
    );

    return (
        <Dialog open={true} fullWidth={true} maxWidth="lg">
            <DialogTitle>We have updated our terms and conditions</DialogTitle>
            <DialogContent sx={{ color: 'black' }}>
                <ExitImpersonationAlert />

                <p>
                    Hey, {user.firstName} {user.lastName}! We have updated some
                    of our documents. Please read the updated versions below.
                </p>

                <FormGroup>
                    {commercialTerms?.isAccepted === false &&
                    dataTerms?.isAccepted === false ? (
                        <>
                            {dataTerms?.comment && <p>{dataTerms?.comment}</p>}

                            {commercialTerms?.comment && (
                                <p>{commercialTerms?.comment}</p>
                            )}

                            <ConsentCheckbox
                                checked={commercialConsent && dataConsent}
                                setChecked={(checked) => {
                                    setCommercialConsent(checked);
                                    setDataConsent(checked);
                                }}
                                label={
                                    <>
                                        <span>
                                            I have read and accepted the{' '}
                                        </span>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={commercialTerms?.url}
                                        >
                                            license agreement
                                        </a>
                                        <span> and the </span>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={dataTerms?.url}
                                        >
                                            data processing agreement
                                        </a>
                                        .
                                    </>
                                }
                            />
                        </>
                    ) : (
                        <>
                            {commercialTerms?.isAccepted === false && (
                                <>
                                    {commercialTerms?.comment && (
                                        <p>{commercialTerms?.comment}</p>
                                    )}

                                    <ConsentCheckbox
                                        checked={commercialConsent}
                                        setChecked={(checked) =>
                                            setCommercialConsent(checked)
                                        }
                                        label={
                                            <>
                                                <span>
                                                    I have read and accepted the{' '}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={commercialTerms?.url}
                                                >
                                                    license agreement
                                                </a>
                                                .
                                            </>
                                        }
                                    />
                                </>
                            )}

                            {dataTerms?.isAccepted === false && (
                                <>
                                    {dataTerms?.comment && (
                                        <p>{dataTerms?.comment}</p>
                                    )}

                                    <ConsentCheckbox
                                        checked={dataConsent}
                                        setChecked={(checked) =>
                                            setDataConsent(checked)
                                        }
                                        label={
                                            <>
                                                <span>
                                                    I have read and accepted the{' '}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={dataTerms?.url}
                                                >
                                                    data processing agreement
                                                </a>
                                                .
                                            </>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}

                    {props.termsVersions.hasAcceptedNewsletterAndUpdates ===
                        false && (
                        <ConsentCheckbox
                            checked={newsletterConsent}
                            setChecked={(checked) =>
                                setNewsletterConsent(checked)
                            }
                            label="I hereby give my consent to receive newsletters and information from InstaPaid via email or through the InstaPaid application regarding InstaPaid's product range, such as updates and new features in the InstaPaid application. InstaPaid will process my information, including name, contact details, and company, for this purpose."
                        />
                    )}
                </FormGroup>

                <p>Thank you for choosing InstaPaid!</p>
            </DialogContent>
            <ConsentFormActions
                commercialConsent={commercialConsent}
                dataConsent={dataConsent}
                newsletterConsent={newsletterConsent}
                postponeConsents={props.postponeConsents}
                updateConsents={props.updateConsents}
                termsVersions={props.termsVersions}
            />
        </Dialog>
    );
};
