import { useState, useEffect, useCallback } from 'react';
import { Alert } from '@mui/material';
import { debounce } from 'lodash';
import { UserTableType, UsersTable } from '../UsersTable';
import { AddUserForm } from '../add/AddUserForm';
import { UpdateUserForm } from '../update/UpdateUserForm';
import { UpdateBankAccountForm } from '../bankAccountDetails/UpdateBankAccountForm';
import {
    ZenegyEmployeeTypeEnum,
    GetZenegyUsersDetailedReqest as GetUsersDetailedRequest,
    ZenegySalaryModeEnum,
    ZenegySalaryTypeEnum,
    UserDetailedResponse,
    ZenegyUserDetailed,
    CompanyUsersResponse
} from '../../../models/payment';
import { ZenegyUsersFilters } from './ZenegyUsersFilters';
import { useErrorHandling } from '../../../utils/errorHandling';
import { useEmployeesOverview } from '../EmployeesOverview';
import {
    WithConfirmation,
    WithConfirmationWrappedComponentProps
} from '../../../utils/withConfirmation';
import { useEmployeeStatusConfirmation } from '../useEmployeeStatusConfirmation';
import { useParams } from 'react-router-dom';
import { useAppDispatch, usePayments, useUsers } from '../../../lib/hooks';
import { FETCH_STATE } from '../../../lib/slices/types';
import {
    getCompaniesUsers,
    getZenergyUsersDetailed
} from '../../../lib/slices/paymentsSlice';
import { checkValueAndCall } from '../../../lib/functionUtil';
import { ActiveType, getFilterToParams } from '../UserActiveFilter';
import { setLoader } from '../../../lib/slices/globalLoaderSlice';
import { SettingsUsersTable } from '../SettingsUserTable';
import {
    getRowsPerPageForUserTable,
    saveRowsPerPageForUserTable
} from '../../../lib/localStorage';

interface ZenegyEmployeesProps extends WithConfirmationWrappedComponentProps {
    displaySection: boolean;
}

export const ZenegyEmployeesOverview = WithConfirmation(
    (props: ZenegyEmployeesProps) => {
        const { showConfirmDialog, displaySection } = props;

        const [usersDetailed, setUsersDetailed] =
            useState<UserDetailedResponse<ZenegyUserDetailed>>();

        const [companyUsersDetailed, setCompanyUsersDetailed] =
            useState<CompanyUsersResponse>();

        const [error, setError] = useState<string>();
        const [updateUserId, setUpdateUserId] = useState<string>();
        const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
        const [updateBankAccountUserId, setUpdateBankAccountUserId] =
            useState<string>();
        const [
            updateBankAccountUserDialogOpen,
            setUpdateBankAccountUserDialogOpen
        ] = useState(false);

        const [checked, setChecked] = useState<string[]>([]);

        const [selectedEmployeeTypes, setSelectedEmployeeTypes] = useState<
            ZenegyEmployeeTypeEnum[]
        >([]);

        const [selectedPandayEmployeeIds, setPandayEmployeeTypesIds] = useState<
            string[]
        >([]);
        const [selectedSalaryModes, setSelectedSalaryModes] = useState<
            ZenegySalaryModeEnum[]
        >([]);
        const [selectedSalaryTypes, setSelectedSalaryTypes] = useState<
            ZenegySalaryTypeEnum[]
        >([]);
        const [selectedDepartments, setSelectedDepartments] = useState<
            string[]
        >([]);

        const [selectedActiveType, setSelectedActiveType] =
            useState<string>('');

        const [username, setUsername] = useState<string>('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(16);
        const { backendErrorHandler } = useErrorHandling();
        const {
            onRowClick,
            onCheckAllClick,
            allUsersChecked,
            onCheckAllCompanyUsersClick,
            allCompanyUsersChecked
        } = useEmployeesOverview();

        const { pageNumber } = useParams();

        useEffect(() => {
            if (pageNumber) {
                setPage(parseInt(pageNumber));
            }
        }, [pageNumber]);

        const dispatch = useAppDispatch();

        const { zenergyUsersDetailed, companyUsers } = usePayments();

        useEffect(() => {
            dispatch(
                setLoader(zenergyUsersDetailed.status === FETCH_STATE.LOADING)
            );
            if (zenergyUsersDetailed.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Users could not be fetched');
                });
            }
            if (zenergyUsersDetailed.status == FETCH_STATE.SUCCEEDED) {
                setUsersDetailed(zenergyUsersDetailed.usersResponse);
            }
        }, [zenergyUsersDetailed]);

        const reloadUsersPaymentsService = useCallback(() => {
            let activeStatus = getFilterToParams(selectedActiveType);

            const request: GetUsersDetailedRequest = {
                salaryModes: selectedSalaryModes,
                departmentIds: selectedDepartments ?? [],
                employeeTypes: selectedEmployeeTypes,
                salaryTypes: selectedSalaryTypes,
                plandayEmployeeTypeIds: selectedPandayEmployeeIds,
                ...activeStatus,
                username,
                limit: rowsPerPage > 0 ? rowsPerPage : 0, // can be set to -1 to show all
                offset: page * rowsPerPage
            };

            dispatch(getZenergyUsersDetailed(request));
        }, [
            selectedSalaryModes,
            selectedDepartments,
            selectedEmployeeTypes,
            selectedSalaryTypes,
            selectedPandayEmployeeIds,
            selectedActiveType,
            username,
            page,
            rowsPerPage
        ]);

        let debouncedReload = debounce(() => {
            reloadUsersPaymentsService();
        }, 100);

        const reloadUsers = useCallback(() => {
            debouncedReload?.cancel();
            debouncedReload();
            setChecked([]);
        }, [reloadUsersPaymentsService]);

        useEffect(() => {
            let rowsPerPage = getRowsPerPageForUserTable();
            setRowsPerPage(rowsPerPage);
        }, []);

        useEffect(() => {
            reloadUsers();
        }, [reloadUsers]);

        useEffect(() => {
            dispatch(setLoader(companyUsers.status === FETCH_STATE.LOADING));
            if (companyUsers.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Company Admins could not be fetched');
                });
            }
            if (companyUsers.status == FETCH_STATE.SUCCEEDED) {
                setCompanyUsersDetailed(companyUsers?.companySettingsUsers);
            }
        }, [companyUsers]);

        const reloadCompanyUsersService = useCallback(() => {
            dispatch(
                getCompaniesUsers({
                    limit: rowsPerPage > 0 ? rowsPerPage : 0,
                    offset: page * rowsPerPage
                })
            );
        }, [page, rowsPerPage]);

        let debouncedCompanyUsersReload = debounce(() => {
            reloadCompanyUsersService();
        }, 100);

        const reloadCompanyUsers = useCallback(() => {
            debouncedCompanyUsersReload?.cancel();
            debouncedCompanyUsersReload();
            setChecked([]);
        }, [reloadCompanyUsersService]);

        useEffect(() => {
            reloadCompanyUsers();
        }, [reloadCompanyUsers]);

        const onEditClick = (userId: string) => () => {
            setUpdateUserId(userId);
            setUpdateUserDialogOpen(true);
        };

        const onUpdateBankAccountDetailsClick = (userId: string) => () => {
            setUpdateBankAccountUserId(userId);
            setUpdateBankAccountUserDialogOpen(true);
        };

        const handleSalaryModesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedSalaryModes);
        };

        const handleSalaryTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedSalaryTypes);
        };

        const handleEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedEmployeeTypes);
        };

        const handlePlandayEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setPandayEmployeeTypesIds);
        };

        const handleDepartmentChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedDepartments);
        };

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
        ) => {
            setPage(newPage);
            saveRowsPerPageForUserTable(rowsPerPage);
        };

        const handleChangeRowsPerPage = (rowsPerPage: number) => {
            setRowsPerPage(rowsPerPage);
            saveRowsPerPageForUserTable(rowsPerPage);
            setPage(0);
        };

        const {
            handleBulkEnable,
            handleBulkDisable,
            handleStatusChange,
            handleRoleToggleChange,
            sendActivationEmail,
            handleDeleteUser
        } = useEmployeeStatusConfirmation(
            showConfirmDialog,
            reloadUsers,
            reloadCompanyUsers,
            displaySection
        );

        const handleSentActivationEmail = (userId: string, name: string) => {
            sendActivationEmail(userId, name, setError);
        };

        const handleSetSelectedActiveType = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedActiveType);
        };

        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {usersDetailed && (
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: -80
                            }}
                        >
                            {!displaySection && (
                                <AddUserForm onUserListChange={reloadUsers} />
                            )}
                        </div>
                        {!displaySection && (
                            <ZenegyUsersFilters
                                onPlanDayEmployeeTypeChange={
                                    handlePlandayEmployeeTypesChange
                                }
                                selectedPlanDayEmployeeTypes={
                                    selectedPandayEmployeeIds
                                }
                                selectedDepartments={selectedDepartments}
                                onDepartmentChange={handleDepartmentChange}
                                selectedSalaryModes={selectedSalaryModes}
                                onSalaryModeChange={handleSalaryModesChange}
                                selectedEmployeeTypes={selectedEmployeeTypes}
                                onEmployeeTypeChange={handleEmployeeTypesChange}
                                selectedSalaryTypes={selectedSalaryTypes}
                                onSalaryTypeChange={handleSalaryTypesChange}
                                username={username}
                                onUsernameChange={setUsername}
                                selectedActiveType={selectedActiveType}
                                onActiveTypeChange={handleSetSelectedActiveType}
                            />
                        )}

                        {!displaySection ? (
                            <UsersTable
                                total={
                                    zenergyUsersDetailed?.usersResponse
                                        ?.filteredTotal
                                }
                                handleDeleteUser={(userId: string) =>
                                    handleDeleteUser(userId, reloadUsers)
                                }
                                checked={checked}
                                allChecked={allUsersChecked(
                                    usersDetailed,
                                    checked
                                )}
                                onRowClick={(userId: string) =>
                                    onRowClick(userId, checked, setChecked)
                                }
                                onCheckAllClick={() =>
                                    onCheckAllClick(
                                        usersDetailed,
                                        checked,
                                        setChecked
                                    )
                                }
                                onStatusClick={(
                                    userId: string,
                                    isActive: boolean
                                ) =>
                                    handleStatusChange(
                                        userId,
                                        isActive,
                                        reloadUsers
                                    )
                                }
                                onRoleToggleChange={(
                                    userId: string,
                                    isAdmin: boolean
                                ) =>
                                    handleRoleToggleChange(
                                        userId,
                                        isAdmin,
                                        reloadUsers
                                    )
                                }
                                onEditClick={onEditClick}
                                onUpdateBankAccountDetailsClick={
                                    onUpdateBankAccountDetailsClick
                                }
                                onSendActivationMailClicked={
                                    handleSentActivationEmail
                                }
                                usersDetailed={usersDetailed}
                                handleBulkEnable={handleBulkEnable}
                                handleBulkDisable={handleBulkDisable}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={
                                    handleChangeRowsPerPage
                                }
                                rowsPerPage={rowsPerPage}
                                userTableType={UserTableType.Zenergy}
                            />
                        ) : (
                            companyUsersDetailed && (
                                <SettingsUsersTable
                                    addUserForm={
                                        <AddUserForm
                                            onUserListChange={
                                                reloadCompanyUsers
                                            }
                                        />
                                    }
                                    total={companyUsersDetailed?.total}
                                    checked={checked}
                                    allChecked={allCompanyUsersChecked(
                                        companyUsersDetailed,
                                        checked
                                    )}
                                    onRowClick={(userId: string) =>
                                        onRowClick(userId, checked, setChecked)
                                    }
                                    onCheckAllClick={() =>
                                        onCheckAllCompanyUsersClick(
                                            companyUsersDetailed,
                                            checked,
                                            setChecked
                                        )
                                    }
                                    onStatusClick={(
                                        userId: string,
                                        isActive: boolean
                                    ) =>
                                        handleStatusChange(
                                            userId,
                                            isActive,
                                            reloadCompanyUsers
                                        )
                                    }
                                    onRoleToggleChange={(
                                        userId: string,
                                        isAdmin: boolean
                                    ) =>
                                        handleRoleToggleChange(
                                            userId,
                                            isAdmin,
                                            reloadCompanyUsers
                                        )
                                    }
                                    onReloadUser={reloadCompanyUsers}
                                    onUpdateBankAccountDetailsClick={
                                        onUpdateBankAccountDetailsClick
                                    }
                                    onSendActivationMailClicked={
                                        handleSentActivationEmail
                                    }
                                    usersDetailed={companyUsersDetailed}
                                    handleBulkEnable={handleBulkEnable}
                                    handleBulkDisable={handleBulkDisable}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                    rowsPerPage={rowsPerPage}
                                    userTableType={UserTableType.Zenergy}
                                    handleDeleteUser={(userId: string) =>
                                        handleDeleteUser(
                                            userId,
                                            reloadCompanyUsers
                                        )
                                    }
                                />
                            )
                        )}
                    </div>
                )}

                <UpdateUserForm
                    onUserListChange={reloadUsers}
                    userId={updateUserId}
                    open={updateUserDialogOpen}
                    setOpen={(open: boolean) => setUpdateUserDialogOpen(open)}
                />
                <UpdateBankAccountForm
                    onUserListChange={reloadUsers}
                    userId={updateBankAccountUserId}
                    open={updateBankAccountUserDialogOpen}
                    setOpen={(open: boolean) =>
                        setUpdateBankAccountUserDialogOpen(open)
                    }
                />
            </>
        );
    }
);
