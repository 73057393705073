import { useContext, useMemo, useState } from 'react';
import {
    Card,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow
} from '@mui/material';
import {
    formatDate,
    formatDateTimeToTime,
    hoursOrMinutes,
    toDecimal,
    useFormatting
} from '../../../utils/formatting';
import { useTranslation } from 'react-i18next';
import { ShiftStatusWithdrawAllowed } from '@instapaid/shared-logic';
import { UserContext } from '../../../contexts/user/UserContext';
import {
    TableCell,
    TableCellFirstCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellFirstCell
} from '../../../components/shared/styles/Table';
import Chip, { chipColors } from '../../../components/shared/Chip';
import { AvailableShift } from '../../../services/PaymentsServiceV2';
import CardHeader from '../../../components/shared/CardHeader';
import { SMBold } from '../../../typography/Typography';
import { Status } from '../UserTransactions';

export type AvailableShiftsTableProps = {
    userId: string;
    payrollPeriod: string;
    shifts?: AvailableShift[];
    shiftStatusWithdrawAllowed?: ShiftStatusWithdrawAllowed;
    payoutType: string;
    companyName: string;
    heading?: string;
};

export const AvailableShiftsTable = ({
    shifts,
    shiftStatusWithdrawAllowed,
    payoutType,
    heading,
    companyName
}: AvailableShiftsTableProps) => {
    const { company: adminCompany } = useContext(UserContext);
    const { getBalanceLabel, toDecimal } = useFormatting();

    const sortedShifts = shifts
        ?.slice()
        .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

    const totalWorkBreak = sortedShifts?.reduce(
        (acc, shift) => acc + shift.break * 60,
        0
    );

    const totalWorkedHours = sortedShifts?.reduce(
        (acc, shift) => acc + shift.duration,
        0
    );

    const totalEarnings = sortedShifts?.reduce(
        (acc, shift) => acc + shift.amount,
        0
    );

    const { t } = useTranslation();

    return (
        <>
            {sortedShifts && sortedShifts?.length > 0 && (
                <Card>
                    <CardHeader
                        label={heading}
                        description={`${companyName} - ${t('payout_type', {
                            payoutType
                        })}`}
                        chip={
                            <Chip
                                icon={<></>}
                                label={
                                    <SMBold
                                        color={chipColors.success.text}
                                    >{`${sortedShifts.length}`}</SMBold>
                                }
                                color={'success'}
                            />
                        }
                    />
                    <Grid md={12}></Grid>

                    <TableContainer component={Paper}>
                        <Table aria-label="shifts table">
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCellFirstCell>
                                        Date
                                    </TableHeaderCellFirstCell>
                                    <TableHeaderCell>Time</TableHeaderCell>
                                    <TableHeaderCell>Pause</TableHeaderCell>
                                    <TableHeaderCell>
                                        Employee Group
                                    </TableHeaderCell>
                                    <TableHeaderCell>Hours</TableHeaderCell>
                                    <TableHeaderCell
                                        style={{ textAlign: 'right' }}
                                    >
                                        Hourly Rate
                                    </TableHeaderCell>
                                    <TableHeaderCell>
                                        Hourly Rate From
                                    </TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>

                                    <TableHeaderCell align="right">
                                        Earnings
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedShifts?.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCellFirstCell>
                                            {formatDate(new Date(row.date))}
                                        </TableCellFirstCell>
                                        <TableCell>
                                            {row.startTime && row.endTime ? (
                                                <>
                                                    {formatDateTimeToTime(
                                                        row.startTime
                                                    )}{' '}
                                                    -{' '}
                                                    {formatDateTimeToTime(
                                                        row.endTime
                                                    )}
                                                </>
                                            ) : (
                                                <>Period not reported</>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {hoursOrMinutes(row.break)}{' '}
                                        </TableCell>
                                        <TableCell>{row.groupName}</TableCell>
                                        <TableCell style={{ width: '40px' }}>
                                            {toDecimal(row.duration, 1)}
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: 'right' }}
                                        >
                                            {getBalanceLabel(
                                                row.hourlyRate,
                                                adminCompany.currency
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {row.hourlyRateFrom}
                                        </TableCell>

                                        <TableCell style={{ width: '138px' }}>
                                            <Status status={'Available'} />
                                        </TableCell>

                                        <TableCell
                                            align="right"
                                            style={{
                                                color: row.isShiftTypeExcluded
                                                    ? 'grey'
                                                    : undefined
                                            }}
                                            // color={!row.availableForPayout}
                                        >
                                            {getBalanceLabel(
                                                row.amountV2,
                                                adminCompany.currency
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>
                                        You earned
                                    </TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>
                                        {toDecimal(totalWorkBreak, 0)} mins
                                    </TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell style={{ width: '80px' }}>
                                        {toDecimal(totalWorkedHours, 2)}
                                    </TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell align="right">
                                        {getBalanceLabel(
                                            totalEarnings,
                                            adminCompany.currency
                                        )}
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                        </Table>
                    </TableContainer>
                </Card>
            )}
        </>
    );
};
