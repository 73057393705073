import { useEffect, useState, useCallback } from 'react';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2, { Shift } from '../../services/PaymentsServiceV2';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';

export const useShifts = (userId: string) => {
    const { setLoading } = useLoader();
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [shiftsError, setShiftsError] = useState<string>();

    const { backendErrorHandler } = useErrorHandling();
    const dispatch = useAppDispatch();

    const getShifts = useCallback(() => {
        dispatch(setLoader(true));
        PaymentsAPIServiceV2.getInstance()
            .getUserShifts(userId)
            .then((response: any) => {
                setShifts(response.data.shifts);
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setShiftsError(
                        'Error fetching shifts. ' + errorDetails?.detail
                    );
                })
            )
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        getShifts();
    }, []);

    return {
        shifts,
        shiftsError
    } as const;
};
