import { ApprovalType, LowBalanceAudience, PaymentSource } from './company';
import { TransactionStatus } from './transaction';

export type AiiaExchangeCodeRequest = {
    code: string;
    consentId: string;
};

export type ImportUser = {
    userId: string | null;
    zenegyUserId: string;
    name: string | null;
    email: string | null;
    phoneNumber: string | null;
};

export type PaymentsUser = {
    userId: string;
    email: string;
    bankCode: string;
    bankAccountNumber: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: string;
    isActive: boolean;
    isDisabled: boolean;
};

export type ZenegyEmployeeTypeEnum = 'Employee' | 'Consultant' | 'Honorary';

export type ZenegySalaryModeEnum = 'Hourly' | 'Fixed' | 'Combined';

export type ZenegySalaryTypeEnum =
    | 'Fixed'
    | 'TimePaidWithHoliday'
    | 'TimePaidWithoutHoliday';

export type UserActiveTypeEnum = 'Enabled' | 'Disabled' | 'All' | undefined;

export type ZenegyUserDetailed = PaymentsUser & {
    userExistsInZenegy: boolean;
    departmentId: string;
    departmentName: string;
    employeeType: ZenegyEmployeeTypeEnum;
    salaryMode: ZenegySalaryModeEnum;
    salaryType: ZenegySalaryTypeEnum;
    timeRegistrationProviderId: string | null;
    plandayEmployeeTypeName: string | null;
};

export type IntectUserDetailed = PaymentsUser & {
    userExistsInIntect: boolean;
    departmentId: number;
    departmentName: string;
    employmentTemplateId: number;
    employmentTemplateName: string;
    timeRegistrationProviderId: string | null;
    plandayEmployeeTypeName: string | null;
};

export type Department = {
    id: string;
    name: string;
    isUsersSyncEnabled: boolean;
};

export type DepartmentsResponse = {
    departments: Department[];
};

export interface PlanDayEmployeeTypeResponse {
    employeeTypes: PlanDayEmployeeType[];
}

export interface PlanDayEmployeeType {
    id: number;
    name: string;
    description: string;
}

export type UpdateDepartmentsRequest = {
    departments: Department[];
};

export type EmployeeType = {
    id: string;
    name: ZenegyEmployeeTypeEnum;
    isUsersSyncEnabled: boolean;
};

export type EmployeeTypesResponse = {
    employeeTypes: EmployeeType[];
};

export type UpdateEmployeeTypesRequest = {
    employeeTypes: EmployeeType[];
};

export type IntectDepartment = {
    id: string;
    name: string;
    isUsersSyncEnabled: boolean;
};

export type IntectDepartmentsResponse = {
    departments: IntectDepartment[];
};

export type UpdateIntectDepartmentsRequest = {
    departments: IntectDepartment[];
};

export type IntectEmploymentTemplate = {
    id: string;
    name: string;
    isUsersSyncEnabled: boolean;
};

export type IntectEmploymentTemplatesResponse = {
    employmentTemplates: IntectEmploymentTemplate[];
};

export type UpdateIntectEmploymentTemplateRequest = {
    employmentTemplates: IntectEmploymentTemplate[];
};

export type SalaryMode = {
    id: string;
    name: ZenegySalaryModeEnum;
    isUsersSyncEnabled: boolean;
};

export type SalaryModeResponse = {
    salaryModes: SalaryMode[];
};

export type UpdateSalaryModesRequest = {
    salaryModes: SalaryMode[];
};

export type SalaryType = {
    id: string;
    name: ZenegySalaryTypeEnum;
    isUsersSyncEnabled: boolean;
};

export type SalaryTypeResponse = {
    salaryTypes: SalaryType[];
};

export type UpdateSalaryTypesRequest = {
    salaryTypes: SalaryType[];
};

export type IntegrationProvider = 'zenegy' | 'intect' | 'planday';

export type IntegrationConnectionStatus =
    | 'NotConnected'
    | 'Connected'
    | 'ConnectionError';

export type IntegrationStatusResponse = {
    companyId: string;
    status: IntegrationConnectionStatus;
};

export type UpdateSalaryProviderRequest = {
    salaryProvider: SalaryProvider;
};

export type UpdateTimeRegistrationProviderRequest = {
    timeRegistrationProvider: TimeRegistrationProvider;
};

export type UserDetailed = ZenegyUserDetailed | IntectUserDetailed;

export type UsersDetailedResponse<T = UserDetailed> = {
    total: number;
    filteredTotal: number;
    users: T[];
};

export interface UserDetailedResponse<T> {
    total: number;
    filteredTotal: number;
    users: T[];
}

// export type ZenegyUsersDetailedResponse = {
//   users: ZenegyUserDetailed[];
// } & TypeUserDetailedRepsonse;

// export type IntectUsersDetailedResponse = {
//   users: IntectUserDetailed[];
// } & TypeUserDetailedRepsonse;

export type ImportUsersResponse = {
    jobId: string;
};

export type JobType = 'SyncEmployees';

export type JobStatus =
    | 'NotStarted'
    | 'InProgress'
    | 'Succeded'
    | 'Failed'
    | 'Cancelled';

export type JobDetailsResponse = {
    jobId: string;
    jobType: JobType;
    jobStatus: JobStatus;
    companyId: string;
    processedItems: number;
    totalItems: number;
    progress: number;
    newUsersCount: number;
    updatedUsersCount: number;
    deletedUsersCount: number;
    skippedUsersCount: number;
    warnings: string[];
    errors: string[];
    createdAt: Date;
    finishedAt: Date;
};

export type ZenegyExchangeCodeRequest = {
    code: string;
};

export type IntectAuthorizeRequest = {
    username: string;
    password: string;
};

export type PlandaySetupRequest = {
    clientId?: string | null;
    refreshToken?: string | null;
};

export const salaryProviders = ['Zenegy', 'Intect'] as const;
export type SalaryProvider = (typeof salaryProviders)[number];

export const timeRegistrationProviders = ['Zenegy', 'Planday'] as const;
export type TimeRegistrationProvider =
    (typeof timeRegistrationProviders)[number];

export const paymentProviders = ['Aiia', 'DanskeBank', 'None'] as const;
export type PaymentProvider = (typeof paymentProviders)[number];

export const paymentSettingsCombinations = [
    // other combinations are not available yet
    'Aiia', // PaymentSource = CompanyAccount and PaymentProvider = Aiia
    'Danske Bank', // PaymentSource = CompanyAccount and PaymentProvider = DanskeBank
    'InstaPaid' // PaymentSource = InstaPaidAccount and PaymentProvider = None
] as const;
export type PaymentSettingsCombination =
    (typeof paymentSettingsCombinations)[number];

export enum ShiftStatus {
    Registered = 'Registered',
    Approved = 'Approved',
    PunchclockFinished = 'PunchclockFinished'
}

export type CompanyPaymentDetailsResponse = {
    companyId: string;
    zenegyCompanyId?: string;
    intectCompanyId?: string;
    plandayCompanyId?: string;
    aiiaAccountId?: string;
    paymentProvider: PaymentProvider;
    aiiaPaymentsAllowedPerAuthorization?: number;
    salaryProvider: SalaryProvider;
    timeRegistrationProvider: TimeRegistrationProvider;
    currency: string;
    allowancePercentage: number;
    latestWithdrawalDay: number;
    hourlyEmployeesPayrollStartDay: number;
    transactionEmployeeText: string;
    transactionEmployerText: string;
    transactionExecutionType: TransactionExecutionType;
    salaryAvailableHour: number;
    timezoneInfoId: string;
    shiftStatusWithdrawAllowed: ShiftStatus;
    usersCount: number;
    activeUsersCount: number;
    pricePerUser: number;
    paymentSource: PaymentSource;
    approvalType: ApprovalType;
    lowBalanceNotificationThreshold: number | null;
    notifyPendingWithdrawalsEmail: string | null;
    lowBalanceAudience: LowBalanceAudience | null;
    lowBalanceEmail: string | null;
};

export type CompanyBalanceResponse = {
    companyId: string;
    paymentProvider: PaymentProvider;
    paymentSource: PaymentSource;
    currentBalance: number;
    awaitingAdminApprovalTotal: number;
    availableBalance: number;
    currency: string;
};

export type ConsentType = 'Commercial' | 'Data' | 'NewsletterAndUpdates';

export type TermsVersionResponse = {
    versionId: string;
    consentType: ConsentType;
    name: string;
    url: string;
    latestAcceptanceDate: Date | null;
    isAccepted: boolean;
    comment: string | null;
};

export type TermsVersionsResponse = {
    canBePostponed: boolean;
    hasAcceptedTerms: boolean;
    hasAcceptedNewsletterAndUpdates: boolean;
    termsVersions: TermsVersionResponse[];
};

export type ApproveTransactionsRequest = {
    transactionIds: string[];
};

export type GetAiiaAuthorizeUrlRequest = {
    transactionIds: string[];
};

export type RejectTransactionsRequest = {
    transactionIds: string[];
};

export type GetAiiaRetryAuthorizeUrlRequest = {
    transactionId: string;
};

export type GetAiiaAuthorizeUrlResponse = {
    authorizeUrl: string;
};

export type Payment = {
    amount: number;
    status: TransactionStatus;
    message: string;
};

export type PaymentsResponse = Array<Payment>;

export enum TransactionExecutionType {
    Normal = 'Normal',
    Instant = 'Instant'
}

export type GetZenegyUsersDetailedReqest = {
    departmentIds: string[];
    plandayEmployeeTypeIds: string[];
    salaryModes: ZenegySalaryModeEnum[];
    salaryTypes: ZenegySalaryTypeEnum[];
    employeeTypes: ZenegyEmployeeTypeEnum[];
    username: string;
    limit: number;
    offset: number;
    isActive?: boolean;
    isDisabled?: boolean;
};

export type CreateCompanyAccountTransactionRequest = {
    companyId: string;
    amount: number;
};

export type GetIntectUsersDetailedReqest = {
    departmentIds: string[];
    employmentTemplateIds: string[];
    plandayEmployeeTypeIds: string[];
    username: string;
    limit: number;
    offset: number;
    isActive?: boolean;
    isDisabled?: boolean;
};

export type UpdateUserBankAccountDetailsRequest = {
    userId: string;
    bankCode: string;
    bankAccountNumber: string;
};

export type UpdateCompanyPaymentsRequest = {
    allowancePercentage: number | null;
    latestWithdrawalDay: number | null;
    hourlyEmployeesPayrollStartDay: number | null;
    currency: string;
    transactionEmployerText: string | null;
    transactionEmployeeText: string | null;
    transactionExecutionType: TransactionExecutionType;
    salaryAvailableHour: number;
    timezoneInfoId: string;
    shiftStatusWithdrawAllowed: ShiftStatus | undefined;
    paymentProvider: PaymentProvider;
    paymentSource: PaymentSource;
    approvalType: ApprovalType;
    lowBalanceNotificationThreshold: number | null;
    notifyPendingWithdrawalsEmail: string | null;
    lowBalanceAudience: LowBalanceAudience;
    lowBalanceEmail: string | null;
};

export type CompanyDanskeBankSettings = {
    companyId: string | undefined;
    cvr: string | any;
    iban: string | any;
    name: string | any;
};

export type UpdateCompanyDanskeBankSettingsRequest = {
    cvr: string;
    iban: string;
    name: string;
};

export type SendDeleteCompanyVerificationEmailRequest = {
    companyId: string;
};

export type DeleteCompanyRequest = {
    companyId: string;
    verificationCode: string;
};

export type Provider = {
    name: string;
    transactionExecutionTypes: TransactionExecutionType[];
};

export type Timezone = {
    value: string;
    label: string;
};

export type TimezonesResponse = {
    timezones: Timezone[];
};

export type PlandayShiftTypes = {
    shiftTypeId: string;
    name: string;
    availableForPayout: boolean;
};

export type CompanyDetailsResponse = {
    companyId?: string;
    currency?: string;
    name?: string;
    timeZoneInfoId?: string;
    nameAlias?: string;
    vatNumber?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhoneNo?: string;
    contactEmail?: string;
    allowancePercentage?: string | null;
    latestWithdrawalDay?: number | null;
    salaryAvailableHour?: number | null;
    hourlyEmployeesPayrollStartDay?: number;
    transactionEmployeeText?: string;
    notifyPendingWithdrawalsEmail?: string | null;
    defaultLanguage?: string;
    greetingText?: string | null;
    primaryColor?: string | undefined;
    onPrimaryColor?: string | undefined;
    surfaceColor?: string | undefined;
    onSurfaceColor?: string | undefined;
    backgroundColor?: string | undefined;
    onBackgroundColor?: string | undefined;
    fileUrl?: string | null;
    isCompanyGroup?: boolean;
    creditAmount?: string;
    loginType?: string;
    enableAllowCredit?: boolean;
    enableCompanyGroup?: boolean;
    tenantId?: string;
    customerId?: string;
    transactionId?: string;
    transactionExecutionType?: TransactionExecutionType;
    shiftStatusWithdrawAllowed?: ShiftStatus | undefined;
    paymentSource?: PaymentSource;
    paymentProvider?: PaymentProvider;
    transactionEmployerText?: string | null;
    approvalType?: ApprovalType;
    lowBalanceNotificationThreshold?: number | null;
    lowBalanceAudience?: LowBalanceAudience | null;
    lowBalanceEmail?: string | null;
    timeRegistrationProvider?: TimeRegistrationProvider;
    zenegyCompanyId?: string;
    allowedAvailableShifts?: boolean;
    intectCompanyId?: string;
    plandayCompanyId?: string | null;
    aiiaAccountId?: string | null;
    aiiaPaymentsAllowedPerAuthorization?: number;
    bankAccountNumber?: string | null;
    transactionCompanyText?: string | null;
    country?: string | null;
    plandayShiftTypes?: PlandayShiftTypes[];
    companyEmail?: string;
    salaryProvider?: SalaryProvider;
    endOfTrial?: Date | null;
    pricePerUser?: number;
    usersCount?: number;
    activeUsersCount?: number;
    enableCustomTransactionMessages?: boolean | undefined;
    companyGroupId?: string;
    employeeTypePrimarySource?: any;
    phoneNumberPrimarySource?: any;
    enabledAvailableShiftsInBalance?: boolean;
    enabledUsersAutoEnableByPlandayEmployeeTypes?: boolean;
    notifyPendingWithdrawals?: boolean;
    notifyPendingWithdrawalsHours?: string | null;
    sendWithdrawalsEndOfMonth?: boolean;
    createdAt?: Date;
    plandayCompanyToken?: string | null;
    intectCompanyToken?: string | null;
};

export interface CompanyUsersResponse {
    companySettingsUsers: CompanyUsers[];
    total: number;
}
export interface CompanyUsers {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isGlobalAdmin: boolean;
    isActive: boolean;
    isDisabled: boolean;
}

export interface UserCompanies {
    allowancePercentage?: string | null;
    backgroundColor?: string;
    companyId: string;
    country?: string;
    creditAmount?: any;
    currency?: string;
    defaultLanguage?: string;
    enableAllowCredit?: boolean;
    fileUrl?: any;
    greetingText?: string;
    hourlyEmployeesPayrollStartDay?: any;
    latestWithdrawalDay?: any;
    loginType?: string;
    name: string;
    notifyPendingWithdrawalsEmail?: any;
    onBackgroundColor?: string;
    onPrimaryColor?: string;
    onSurfaceColor?: string;
    paymentProvider?: string;
    paymentSource?: string;
    primaryColor?: string;
    salaryAvailableHour?: any;
    salaryProvider?: String;
    surfaceColor?: string;
    tenantId?: string;
    timeZoneInfoId?: any;
    transactionEmployeeText?: string;
    transactionExecutionType?: string;
    theme?: string;
}
export type FailTransactionsRequest = {
    transactionIds: string[];
};

export type SucceedTransactionsRequest = {
    transactionIds: string[];
};

export type User = {
    userID: string;
    userName: string;
    email: string;
    balance: number;
    withdrawed: number;
    percentageWithdrawed: number;
};

export type TransactionsRequestResponse = {
    transactionId: string;
    createdAt: Date;
    amount: number;
    currency: string;
    user: User[];
    status: string;
};
