import { Box, Typography } from '@mui/material';
import { TransactionsHistory } from './TransactionsHistory';
import { CompanyBalance } from './CompanyBalance';
import {
    FONT_WEIGHT,
    Heading,
    TEXT_SIZE,
    LINE_HEIGHT,
    PageSubHeading
} from '../../typography/Typography';
import { ContentWrapper } from '../../components/ContentWrapper';

export const HistoryPage = () => {
    return (
        <ContentWrapper>
            <>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h4" marginBottom="5px">
                        <Heading
                            fontSize={TEXT_SIZE.MEDIUM_LARGE}
                            fontWeight={FONT_WEIGHT.MEDIUM}
                            lineHeight={LINE_HEIGHT.XLARGE}
                        >
                            Transactions history
                        </Heading>
                        <PageSubHeading>
                            Track, manage and forecast your customers and
                            orders.
                        </PageSubHeading>
                    </Typography>
                    <CompanyBalance />
                </Box>
                <TransactionsHistory />
            </>
        </ContentWrapper>
    );
};
